<template>
  <div>
    <loading
      name="lp"
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
    <card v-if="sessionNotFound">
      <div slot="header">
        <div class="row">
          <div class="col-12">
            <h3>Sessiya tapılmadı. Əsas səhifəyə keçin və yenidən cəhd edin.</h3>
          </div>
          <div class="col-12">
            <l-button
              type="default"
              wide
              outline
              @click="$router.push({ name: 'AccountMain' })"
            >
              <i class="nc-icon left-arrow"></i>

              Ana səhifə
            </l-button>
          </div>
        </div>
      </div>
    </card>
    <card v-if="!sessionNotFound">
      <div class="row">
        <div class="col-12">
          <l-button
            type="default"
            wide
            outline
            @click="$router.push({ name: 'AccountMain' })"
          >
            <i class="nc-icon left-arrow"></i>

            Ana səhifə
          </l-button>
        </div>
      </div>
      <h2>İmtahan nəticəsi</h2>
      <div class="row">
        <div class="col-lg-6">
          <div class="row">
            <div class="col-lg-4">Kod və başlıq</div>
            <div class="col-lg-8">{{ session.code }}. {{ session.title }}</div>
            <div class="col-lg-4">İmtahan tarixi</div>
            <div class="col-lg-8">{{ startDate }}</div>
            <div class="col-lg-4">İmtahan verən</div>
            <div class="col-lg-8">{{ session.testtakername }}</div>
            <div class="col-lg-4">Email</div>
            <div class="col-lg-8">{{ session.resemail }}</div>
            <div class="col-lg-4">Sualların sayı</div>
            <div class="col-lg-8">{{ session.questioncount }}</div>
            <div class="col-lg-4">Müddət (dəq)</div>
            <div class="col-lg-8">{{ session.duration }}</div>
            <div class="col-lg-4">Keçirilmiş vaxt (dəq)</div>
            <div class="col-lg-8">{{ session.spent }}</div>
            <div class="col-lg-4">Status</div>
            <div class="col-lg-8" :class="statusStyle(session.result)">
              <b>{{ session.resultname }}</b>
            </div>
          </div>
          <div class="row" v-if="$store.state.user.roleid === 3">
            <div class="col-lg-12 text-center">
              <hr />
              <h5>Daha çox test əldə etmək, nəticələri saxlamaq və paylaşmaq üçün qeydiyyatdan keçin</h5>
              <br />
              <l-button
                type="primary"
                wide
                @click="
                  $router.push({
                    path:
                      '/login/Register/' +
                      session.resemail +
                      '/' +
                      session.testtakername,
                  })
                "
              >
                Register
              </l-button>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div
            :key="item.id"
            v-for="item in sections"
            style="margin-bottom: 20px"
          >
            <h6>
              {{ item.name }} -
              <span :class="statusStyle(item.result)">{{
                item.resultname
              }}</span>
            </h6>

            <el-progress
              :text-inside="true"
              :stroke-width="26"
              :percentage="item.resultscore"
              :status="item.result === 1 ? 'success' : 'exception'"
            ></el-progress>
            <small>Keçid balı: {{ item.scoremin + "%" }}</small>
          </div>
        </div>
      </div>
    </card>
  </div>
</template>

<script>
import {
  DateTimeToString,
  ShowLink,
  HtmlNewLine,
  uploadUrl,
  fileUrl,
} from "../../services/helper";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Card from "../../components/Cards/Card.vue";
import { GetSession, GetSessionSections } from "../../services/know";
import { Dialog, Progress } from "element-ui";
import { Checkbox, Radio, Table as LTable } from "src/components/index";
import Swal from "sweetalert2";
import Pagination from "../../components/Pagination.vue";
export default {
  components: {
    [Dialog.name]: Dialog,
    [Progress.name]: Progress,
    Loading,
    Checkbox,
    Radio,
    LTable,
  },

  props: {
    id: String,
  },
  computed: {
    startDate() {
      return this.session.startdate
        ? DateTimeToString(this.session.startdate)
        : "";
    },
  },
  data() {
    return {
      isLoading: false,
      session: {},
      sections: [],
      sessionNotFound: false,
    };
  },
  methods: {
    statusStyle(result) {
      return { "text-success": result === 1, "text-danger": result === 0 };
    },
  },
  mounted() {
    this.isLoading = true;
    GetSession(this.id, this.$store.state.user.id).then((response) => {
      if (response.data.result) {
        this.session = response.data.result;
        GetSessionSections(this.id, this.$store.state.user.id).then(
          (response2) => {
            this.isLoading = false;
            if (response2.data.result) {
              this.sections = response2.data.result;
            } else {
            }
          }
        );
      } else {
        this.sessionNotFound = true;
        this.isLoading = false;
        this.notifyVue(
          "danger",
          response.data ? response.data : "Sessiya tapılmadı!"
        );
      }
    });
  },
};
</script>

<style lang="scss">
</style>