var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('loading',{attrs:{"name":"lp","active":_vm.isLoading,"can-cancel":false,"is-full-page":true},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [(_vm.test.id)?_c('card',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-8 order-lg-1 order-1 overflow-auto",staticStyle:{"height":"35vh"}},[_c('h3',{staticClass:"mb-4"},[_vm._v(_vm._s(_vm.test.code + ". " + _vm.test.title))]),(_vm.test.id)?_c('h5',[_c('small',[_vm._v("by")]),_vm._v(" "+_vm._s(_vm.test.orgname))]):_vm._e(),_c('blockquote',[_c('p',[_vm._v(_vm._s(_vm.test.description))])])]),_c('div',{staticClass:"col-lg-4 order-lg-2 order-2"},[_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"email","error":failed ? 'The Email field is required' : null,"hasSuccess":passed,"label":"Email address","name":"email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"fullname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? 'The Name field is required' : null,"hasSuccess":passed,"label":"Your name","name":"fullname"},model:{value:(_vm.fullname),callback:function ($$v) {_vm.fullname=$$v},expression:"fullname"}})]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12 text-center"},[_c('h4',[_vm._v(" "+_vm._s(_vm.test.questioncount + " sual " + _vm.test.duration + " dəq." + " ərzində")+" ")]),_c('hr'),_c('div',{staticClass:"text-center"},[_c('l-button',{attrs:{"type":"success","size":"lg"},on:{"click":function($event){return handleSubmit(_vm.onStart)}}},[_vm._v("İmtahanı başla")])],1)])])]):_vm._e()]}}])}),(_vm.isnotaccessible)?_c('card',[_c('div',{staticClass:"row",attrs:{"slot":"header"},slot:"header"},[_c('div',{staticClass:"col-lg order-lg-1 order-1"},[_c('h2',[_vm._v("Test tapılmadı!")])])])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }