<template>
  <div>
    <div class="row">
      <div class="col-xl-3 col-md-6">
        <stats-card :title="stat.categorycount" subTitle="Kateqoriyalar">
          <div slot="header" class="icon-warning">
            <i class="nc-icon filter-organization text-warning"></i>
          </div>
          <template slot="footer">
            <l-button type="default" block wide outline @click="$router.push({ path: '/admin/category' })">
                <i class="nc-icon settings-gear"></i>
              İdarə
            </l-button>
          </template>
        </stats-card>
      </div>

      <div class="col-xl-3 col-md-6">
        <stats-card :title="stat.testcount" subTitle="Testlər">
          <div slot="header" class="icon-success">
            <i class="nc-icon check-list text-success"></i>
          </div>
          <template slot="footer">
            <l-button type="default" block wide outline @click="$router.push({ path: '/admin/tests' })">
                <i class="nc-icon settings-gear"></i>
           
              İdarə
            </l-button>
          </template>
        </stats-card>
      </div>

      <div class="col-xl-3 col-md-6">
        <stats-card title="0" subTitle="Certificates">
          <div slot="header" class="icon-info">
            <i class="nc-icon certificate text-primary"></i>
          </div>
          <template slot="footer">
            <l-button type="default" block wide outline>
                <i class="nc-icon settings-gear"></i>
            
              İdarə
            </l-button>
          </template>
        </stats-card>
      </div>

      <div class="col-xl-3 col-md-6">
        <stats-card :title="stat.sharecount" subTitle="Sharing">
          <div slot="header" class="icon-danger">
            <i class="nc-icon network-communication text-purple"></i>
          </div>
          <template slot="footer">
            <l-button type="default" block wide outline @click="$router.push({ path: '/admin/share' })">
                <i class="nc-icon settings-gear"></i>
           
              İdarə
            </l-button>
          </template>
        </stats-card>
      </div>

      <div class="col-xl-3 col-md-6">
        <stats-card title="0" subTitle="Test takers">
          <div slot="header" class="icon-info">
            <i class="nc-icon b-check text-info"></i>
          </div>
          <template slot="footer">
            <l-button type="default" block wide outline>
                <i class="nc-icon settings-gear"></i>
      
              İdarə
            </l-button>
          </template>
        </stats-card>
      </div>

      <div class="col-xl-3 col-md-6">
        <stats-card title="0" subTitle="Bilik bazası">
          <div slot="header" class="icon-info">
            <i class="nc-icon books text-brown"></i>
          </div>
          <template slot="footer">
            <l-button type="default" block wide outline>
                <i class="nc-icon settings-gear"></i>
           
              İdarə
            </l-button>
          </template>
        </stats-card>
      </div>

      <div class="col-xl-3 col-md-6">
        <stats-card title="0" subTitle="Competitions">
          <div slot="header" class="icon-info">
            <i class="nc-icon final-score text-primary"></i>
          </div>
          <template slot="footer">
            <l-button type="default" block wide outline>
                <i class="nc-icon settings-gear"></i>
           
              İdarə
            </l-button>
          </template>
        </stats-card>
      </div>

<div class="col-xl-3 col-md-6">
        <stats-card title="0" subTitle="Nominations">
          <div slot="header" class="icon-info">
            <i class="nc-icon winner text-primary"></i>
          </div>
          <template slot="footer">
            <l-button type="default" block wide outline>
                <i class="nc-icon settings-gear"></i>
           
              İdarə
            </l-button>
          </template>
        </stats-card>
      </div>

    </div>
  </div>
</template>
<script>
import {
  ChartCard,
  StatsCard,
  Card,
  Table as LTable,
  Checkbox,
} from "src/components/index";
import { GetStat } from "../../services/know"
export default {
  components: {
    Checkbox,
    Card,
    LTable,
    ChartCard,
    StatsCard,
  },
  data() {
    return {
      stat: {}
    };
  },
  created() {
    GetStat(this.$store.state.user.id).then(
      response => {
        if (response.data.categorycount) {
          this.stat = response.data;
        }
      }
    );
  },
};
</script>
<style>
</style>
