<template>
  <div>
    <loading
      name="lp"
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
    <div class="col-12" style="margin-bottom: 10px">
      <el-select
        class="select-primary"
        v-model="sectionid"
        placeholder="Select section"
        @change="onRefresh()"
      >
        <el-option
          class="select-primary"
          v-for="item in secs"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        ></el-option>
      </el-select>
    </div>
    <div
      class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
    >
      <div class="pl-lg-0">
        <button class="btn btn-fill btn-info btn-wd" @click="onNew()" :disabled="secs.length === 0">
          Yeni sual
        </button>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <l-button type="info" wide outline @click="onRefresh()">
          Yenilə
        </l-button>
      </div>

      <el-input
        type="search"
        class="mb-3"
        style="width: 200px"
        placeholder="Search records"
        v-model="searchQuery"
        aria-controls="datatables"
      />
    </div>

    <div class="col-sm-12">
      <el-table stripe style="width: 100%" :data="quests" border>
        <el-table-column type="expand">
          <template slot-scope="props">
            <p>Təsvir:</p>
            <span v-html="parseHtml(props.row.answerdescr)"></span>
            <p><br />Qeydlər:</p>
            <span v-html="parseHtml(props.row.notes)"></span>
          </template>
        </el-table-column>
        <el-table-column :width="60" prop="orderindex" label="#">
        </el-table-column>
        <el-table-column prop="question" label="Question"> </el-table-column>
        <el-table-column :width="80" prop="rate" label="Rate">
        </el-table-column>
        <el-table-column :width="120" prop="answertypename" label="Answer type">
        </el-table-column>
        <el-table-column :width="80" prop="answercount" label="Answer count">
        </el-table-column>
        <el-table-column :width="80" label="Active">
          <template slot-scope="props">
            <div onclick="return false">
              <l-checkbox :checked="props.row.active === 1"> </l-checkbox>
            </div>
          </template>
        </el-table-column>
        <el-table-column :width="60" label="Valid">
          <template slot-scope="props">
            <i
              v-tooltip.top-center="props.row.statusname"
              :class="{
                'text-danger nc-icon t-warning': props.row.status !== 1,
                'text-success nc-icon check-single': props.row.status === 1,
              }"
            />
          </template>
        </el-table-column>
        <el-table-column :width="120" label="Actions">
          <template slot-scope="props">
            <a
              v-tooltip.top-center="'Answers'"
              class="btn-info btn-simple btn-link"
              @click="onAnswer(props.row)"
              ><i class="nc-icon survey-2"></i> </a
            >&nbsp;&nbsp;
            <a
              v-tooltip.top-center="'Edit'"
              class="btn-warning btn-simple btn-link"
              @click="onEdit(props.row)"
              ><i class="nc-icon d-edit"></i> </a
            >&nbsp;&nbsp;
            <a
              v-tooltip.top-center="'Delete'"
              class="btn-danger btn-simple btn-link"
              @click="onDelete(props.row)"
              ><i class="nc-icon d-remove"></i
            ></a>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
      center
      title="Question action"
      :visible.sync="questModal"
      width="90%"
    >
      <ValidationObserver v-slot="{ handleSubmit }">
        <div class="row">
          <div :class="quest.id ? 'col-8' : 'col-12'">
            <div class="row">
              <div class="col-6">
                <ValidationProvider
                  name="tests"
                  rules="required"
                  v-slot="{ passed, failed }"
                >
                  <fg-input
                    label="Section"
                    placeholder="Section"
                    name="section"
                    :error="failed ? 'The Section field is required' : null"
                    :hasSuccess="passed"
                  >
                    <el-select
                      class="select-primary"
                      v-model="quest.sectionid"
                      placeholder="Select section"
                      :error="failed ? 'The Section field is required' : null"
                      :hasSuccess="passed"
                    >
                      <el-option
                        class="select-primary"
                        v-for="item in secs"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                      ></el-option>
                    </el-select>
                  </fg-input>
                </ValidationProvider>
              </div>

              <div class="col-lg-3">
                <ValidationProvider
                  name="orderindex"
                  rules="required"
                  v-slot="{ passed, failed }"
                >
                  <fg-input
                    :min="0"
                    :max="99"
                    type="number"
                    :error="failed ? 'The Order index field is required' : null"
                    :hasSuccess="passed"
                    label="Order index"
                    placeholder="Order index"
                    v-model="quest.orderindex"
                    name="Order index"
                  ></fg-input>
                </ValidationProvider>
              </div>
              <div class="col-lg-3">
                <div v-if="quest.id">
                  <fg-input label="Active" placeholder="Active" name="active">
                    <br />
                    <el-switch v-model="isactive"></el-switch>
                  </fg-input>
                </div>
              </div>
              <div class="col-lg-12">
                <ValidationProvider
                  name="rate"
                  rules="required"
                  v-slot="{ passed, failed }"
                >
                  <fg-input
                    label="Question"
                    :error="failed ? 'The Question field is required' : null"
                    :hasSuccess="passed"
                  >
                    <textarea
                      v-model="quest.question"
                      class="form-control"
                      placeholder="Question"
                      :error="failed ? 'The Question field is required' : null"
                      :hasSuccess="passed"
                      rows="3"
                    ></textarea>
                  </fg-input>
                </ValidationProvider>
              </div>

              <div class="col-lg-4">
                <ValidationProvider
                  name="rate"
                  rules="required"
                  v-slot="{ passed, failed }"
                >
                  <fg-input
                    :min="1"
                    :max="10"
                    type="number"
                    :error="failed ? 'The Rate field is required' : null"
                    :hasSuccess="passed"
                    label="Rate"
                    placeholder="Rate"
                    v-model="quest.rate"
                    name="Rate"
                  ></fg-input>
                </ValidationProvider>
              </div>
              <div class="col-lg-4">
                <fg-input
                  label="Answer type"
                  placeholder="Answer type"
                  name="answertype"
                >
                  <el-select
                    class="select-primary pagination-select"
                    v-model="quest.answertype"
                    placeholder="Answer type"
                  >
                    <el-option
                      class="select-primary"
                      :key="1"
                      :label="'single'"
                      :value="1"
                    ></el-option>
                    <el-option
                      class="select-primary"
                      :key="2"
                      :label="'multiselect'"
                      :value="2"
                    ></el-option>
                    <el-option
                      class="select-primary"
                      :key="3"
                      :label="'user input'"
                      :value="3"
                    ></el-option>
                  </el-select>
                </fg-input>
              </div>
              <div class="col-lg-4">
                <ValidationProvider
                  name="answercount"
                  rules="required"
                  v-slot="{ passed, failed }"
                >
                  <fg-input
                    :min="1"
                    :max="10"
                    type="number"
                    :error="
                      failed ? 'The Answer count field is required' : null
                    "
                    :hasSuccess="passed"
                    label="Answer count"
                    placeholder="Answer count"
                    v-model="quest.answercount"
                    name="answercount"
                  ></fg-input>
                </ValidationProvider>
              </div>

              <div class="col-lg-12">
                <fg-input label="Notes">
                  <textarea
                    v-model="quest.notes"
                    class="form-control"
                    placeholder="Notes"
                    rows="1"
                  ></textarea>
                </fg-input>
              </div>
              <div class="col-lg-12">
                <fg-input label="Answer description">
                  <textarea
                    v-model="quest.answerdescr"
                    class="form-control"
                    placeholder="Answer description"
                    rows="3"
                  ></textarea>
                </fg-input>
              </div>
            </div>
          </div>
          <div v-if="quest.id" class="col-4">
            <h4>Question picture</h4>
            <el-upload
              :action="uploadurl"
              :show-file-list="false"
              :before-upload="beforeUpload"
              :on-success="afterUpload"
            >
              <button size="small" class="btn btn-fill btn-default btn-wd">
                Yüklə
              </button>
            </el-upload>
            <br />
            <img width="100%" :src="picture" />
          </div>
        </div>

        <div slot="footer" class="dialog-footer text-center">
          <button class="btn btn-danger" @click="questModal = false">
            Xitam</button
          >&nbsp;
          <button class="btn btn-success" @click="handleSubmit(onSave)">
            Yadda saxla
          </button>
        </div>
      </ValidationObserver>
    </el-dialog>

    <el-dialog
      center
      title="Answers action"
      :visible.sync="answerModal"
      width="90%"
    >
      <ValidationObserver v-slot="{ handleSubmit }">
        <div class="row">
          <div class="col-8">
            <el-table
              stripe
              style="width: 100%"
              :data="answers"
              border
              height="400"
            >
              <el-table-column prop="answer" label="Answer"> </el-table-column>

              <el-table-column :width="100" label="Status">
                <template slot-scope="props">
                  <span :class="{ 'text-success': props.row.status === 1 }">{{
                    props.row.statusname
                  }}</span>
                </template>
              </el-table-column>

              <el-table-column :width="80" label="Active">
                <template slot-scope="props">
                  <div onclick="return false">
                    <l-checkbox :checked="props.row.active === 1"> </l-checkbox>
                  </div>
                </template>
              </el-table-column>
              <el-table-column :width="120"  label="Actions">
                <template slot-scope="props">
                  <a
                    v-tooltip.top-center="'Edit'"
                    class="btn-warning btn-simple btn-link"
                    @click="onEditAnswer(props.row)"
                    ><i class="nc-icon d-edit"></i> </a
                  >&nbsp;&nbsp;
                  <a
                    v-tooltip.top-center="'Delete'"
                    class="btn-danger btn-simple btn-link"
                    @click="onDeleteAnswer(props.row)"
                    ><i class="nc-icon d-remove"></i
                  ></a>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="col-4">
            <h4>Answer</h4>
            <div class="row">
              <div class="col-lg-12">
                <button
                  v-if="canAddNew"
                  class="btn btn-fill btn-info btn-wd"
                  @click="onNewAnswer()"
                >
                  New answer
                </button>
              </div>
              <div class="col-lg-12">
                <fg-input label="Answer">
                  <textarea
                    v-model="answer.answer"
                    class="form-control"
                    placeholder="Answer"
                    rows="3"
                  ></textarea>
                </fg-input>
              </div>
              <div class="col-lg-12">
                <fg-input label="Status" placeholder="Status" name="status">
                  <el-select
                    class="select-primary pagination-select"
                    v-model="answer.status"
                    placeholder="Status"
                  >
                    <el-option
                      class="select-primary"
                      :key="0"
                      :label="'incorrect'"
                      :value="0"
                    ></el-option>
                    <el-option
                      class="select-primary"
                      :key="1"
                      :label="'correct'"
                      :value="1"
                    ></el-option>
                  </el-select>
                </fg-input>
              </div>
              <div class="col-lg-12">
                <div v-if="answer.id">
                  <fg-input label="Active" placeholder="Active" name="active">
                    <br />
                    <el-switch v-model="isactiveanswer"></el-switch>
                  </fg-input>
                </div>
              </div>
              <div class="col-lg-12">
                <button
                  class="btn btn-primary"
                  @click="handleSubmit(onSaveAnswer)"
                >
                  Yadda saxla
                </button>
              </div>
            </div>
          </div>
        </div>
      </ValidationObserver>
    </el-dialog>
  </div>
</template>

<script>
import {
  ShowLink,
  HtmlNewLine,
  uploadUrl,
  fileUrl,
  noimage,
} from "../../services/helper";
import { GenerateHex } from "../../services/crypto";
import { Create } from "../../services/files";
import Swal from "sweetalert2";
import {
  GetSections,
  GetQuestions,
  CreateQuestion,
  UpdateQuestion,
  DeleteQuestion,
  GetAnswers,
  UpdateAnswer,
  CreateAnswer,
  DeleteAnswer,
} from "../../services/know";
import { QuestionType, FileType, AnswerType } from "../../services/types";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import {
  Select,
  Option,
  Dialog,
  Switch,
  Table,
  TableColumn,
  Upload,
} from "element-ui";
import { Checkbox as LCheckbox } from "src/components/index";
export default {
  name: "question-list",
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dialog.name]: Dialog,
    [Switch.name]: Switch,
    [Upload.name]: Upload,
    Loading,
    LCheckbox,
  },
  computed: {
    uploadurl() {
      return uploadUrl;
    },
    fileurl() {
      return fileUrl;
    },
    picture() {
      return !this.quest.picture ? noimage : this.fileurl + this.quest.picture;
    },
    canAddNew() {
      console.log(this.quest)
      return this.quest
        ? this.quest.answertype !== 3 || this.answers.length < 1
        : false;
    },
  },
  props: {
    id: String,
  },
  data() {
    return {
      isactive: true,
      isactiveanswer: true,
      searchQuery: "",
      isLoading: false,
      sectionid: "",
      secs: [],
      quests: [],
      answers: [],
      answer: {},
      quest: {
        id: "",
        orderindex: 1,
      },
      questModal: false,
      answerModal: false,
    };
  },
  methods: {
    showSwal(type) {
      if (type === "warning") {
        return Swal.fire({
          title: "Are you sure?",
          text: "You will not be able to recover this record!",
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          confirmButtonClass: "btn btn-success btn-fill",
          cancelButtonClass: "btn btn-danger btn-fill",
          buttonsStyling: false,
        });
      }
    },
    getAnswers() {
      GetAnswers(this.quest.id, this.$store.state.user.id).then((response) => {
        if (response.data.result) {
          this.answers = response.data.result;
        } else {
          if (response.data) {
            this.notifyVue("danger", response.data);
            this.answers = [];
          }
        }
      });
    },
    onAnswer(item) {
      this.quest = item;
      this.answers = [];
      this.getAnswers(item);
      this.onNewAnswer();
      this.answerModal = true;
    },
    onEditAnswer(item) {
      this.answer = Object.assign({}, item);
      this.answer.questinonid = this.quest.id;
    },
    onEdit(item) {
      this.quest = Object.assign({}, item);
      this.questModal = true;
    },
    onRefresh() {
      this.getQuestions();
    },
    getSections() {
      if (!this.id) return;
      GetSections(this.id, this.$store.state.user.id).then((response) => {
        if (response.data.result) {
          this.secs = response.data.result;
          if (this.secs.length > 0) {
            this.sectionid = this.secs[0].id;
            this.onRefresh();
          }
        } else {
          if (response.data) this.notifyVue("danger", response.data);
          this.secs = [];
        }
      });
    },
    getQuestions() {
      this.isLoading = true;
      GetQuestions(this.sectionid, this.$store.state.user.id).then(
        (response) => {
          this.isLoading = false;
          if (response.data.result) {
            this.quests = response.data.result;
          } else {
            this.quests = [];
            if (response.data) this.notifyVue("danger", response.data);
          }
        }
      );
    },
    showSwal(type) {
      if (type === "warning") {
        return Swal.fire({
          title: "Are you sure?",
          text: "You will not be able to recover this record!",
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          confirmButtonClass: "btn btn-success btn-fill",
          cancelButtonClass: "btn btn-danger btn-fill",
          buttonsStyling: false,
        });
      }
    },
    onNew() {
      this.quest = new QuestionType();
      this.quest.sectionid = this.secs[0].id;
      this.quest.uid = this.$store.state.user.id;
      this.questModal = true;
    },
    onNewAnswer() {
      this.answer = new AnswerType();
      this.answer.uid = this.$store.state.user.id;
      this.answer.questionid = this.quest.id;
    },
    onChange() {},
    onSave() {
      this.isLoading = true;
      this.quest.uid = this.$store.state.user.id;
      if (!this.quest.id) {
        CreateQuestion(this.quest).then((response) => {
          if (response.data.result) {
            this.isLoading = false;
            this.quest.id = response.data.result;
            // this.secModal = false;
            this.getQuestions();
            this.notifyVue("success", "Changes have been saved!");
          } else {
            this.isLoading = false;
            this.notifyVue("danger", "Action failed!");
          }
        });
      } else {
        this.quest.active = this.isactive ? 1 : 0;
        UpdateQuestion(this.quest).then((response) => {
          if (response.data.result) {
            this.isLoading = false;
            this.secModal = false;
            this.getQuestions();
            this.notifyVue("success", "Changes have been saved!");
          } else {
            this.isLoading = false;
            this.notifyVue("danger", "Action failed!");
          }
        });
      }
    },
    onSaveAnswer() {
      this.isLoading = true;
      this.answer.uid = this.$store.state.user.id;
      if (!this.answer.id) {
        if (!this.canAddNew) {
          this.isLoading = false;
          return;
        }
        CreateAnswer(this.answer).then((response) => {
          if (response.data.result) {
            this.isLoading = false;
            this.onNewAnswer();
            this.getAnswers();
            this.notifyVue("success", "Changes have been saved!");
          } else {
            this.isLoading = false;
            this.notifyVue("danger", "Action failed!");
          }
        });
      } else {
        this.answer.active = this.isactiveanswer ? 1 : 0;
        UpdateAnswer(this.answer).then((response) => {
          if (response.data.result) {
            this.isLoading = false;
            this.onNewAnswer();
            this.getAnswers();
            this.notifyVue("success", "Changes have been saved!");
          } else {
            this.isLoading = false;
            this.notifyVue("danger", "Action failed!");
          }
        });
      }
    },
    parseHtml(item) {
      let res = ShowLink(HtmlNewLine(item));
      return res;
    },
    beforeUpload(file) {
      let fname = file.name;
      let key = GenerateHex();
      let newname = key + "_" + fname;
      Object.defineProperty(file, "name", {
        writable: true,
        value: newname,
      });
    },

    afterUpload(response, file, fileList) {
      if (!response.result) {
        this.notifyVue("danger", "Action failed!");
        return;
      }

      var f = new FileType();
      f.filename = file.raw.name;
      f.sourcetype = 1;
      f.sourceId = this.quest.id;

      this.isLoading = true;

      Create(f).then((response) => {
        this.isLoading = false;
        if (response.data) {
          this.notifyVue("success", "Uploaded!");
          this.quest.picture =
            new Date().getFullYear().toString() + "/" + f.filename;
        } else this.notifyVue("danger", "Action failed!");
      });
    },

    onDelete(item) {
      this.showSwal("warning").then((del) => {
        if (del.isConfirmed) {
          this.isLoading = true;
          DeleteQuestion(item.id, this.$store.state.user.id).then(
            (response) => {
              if (response.data.result) {
                this.isLoading = false;
                this.getQuestions();
                this.notifyVue("success", "Changes have been saved!");
              } else {
                this.isLoading = false;
                this.notifyVue("danger", response.data);
              }
            }
          );
        }
      });
    },

    onDeleteAnswer(item) {
      this.showSwal("warning").then((del) => {
        if (del.isConfirmed) {
          this.isLoading = true;
          DeleteAnswer(item.id, this.$store.state.user.id).then((response) => {
            if (response.data.result) {
              this.isLoading = false;
              this.getAnswers();
              this.notifyVue("success", "Changes have been saved!");
            } else {
              this.isLoading = false;
              this.notifyVue("danger", response.data);
            }
          });
        }
      });
    },
  },
  mounted() {
    this.getSections();
  },
};
</script>

<style lang="scss">
.swal2-container {
  z-index: 10000;
}
</style>