<template>
  <div>
    <loading
      name="lp"
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
    <div class="row">
      <div class="col-lg-3 text-center">
        <router-link class="dropdown-item" :to="{ path: '/account/main/test' }">
          <i class="nc-icon check-list"></i> Testlər</router-link
        >
      </div>
      <div class="col-lg-3 text-center">
        <router-link class="dropdown-item" :to="{ path: '/account/main/kb' }">
          <i class="nc-icon books"></i> Bilik bazası</router-link
        >
      </div>
      <div class="col-lg-6 text-right" v-if="$store.state.user.roleid === 3">
        Hesab əldə etmək üçün &nbsp;&nbsp;&nbsp;
        <l-button
          type="primary"
          @click="
            $router.push({
              path: '/login/Register/',
            })
          "
        >
          Register
        </l-button>
      </div>
    </div>
    <br />
    <div v-if="tab === 'test' || tab === ''" class="row">
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-12">
            <card>
              <div>
                <i
                  style="font-size: 28px; padding-top: 10px"
                  class="nc-icon network-communication"
                ></i
                >&nbsp;&nbsp;
                <span style="font-size: 16pt"><b>Sizinlə paylaşıldı</b></span>
              </div>
              <l-table :data="me">
                <template slot-scope="{ row }">
                  <td>
                    <h6>{{ row.orgname }}</h6>
                    {{ row.code + ". " + row.title }}
                    <br />
                    <small>{{
                      row.questioncount +
                      " sual " +
                      row.duration +
                      " dəq." +
                      " ərzində"
                    }}</small>
                    <br />
                    <small :class="{ 'text-danger': isduedate(row.validto) }"
                      >Active from {{ row.validfromstr }} to
                      {{ row.validtostr }}</small
                    >
                  </td>
                  <td>
                    <l-button
                      type="primary"
                      block
                      outline
                      wide
                      @click="
                        $router.push({ path: '/account/start/' + row.id })
                      "
                    >
                      <span class="btn-label">
                        <i class="fa fa-exclamation"></i>
                      </span>
                      Let's try
                    </l-button>
                  </td>
                </template>
              </l-table>
              <div class="stats text-right" slot="footer">
                <l-button
                  wide
                  simple
                  link
                  @click="$router.push({ path: '/account/shared' })"
                  ><i class="fa fa-history"></i>See all</l-button
                >
              </div>
            </card>
          </div>
          <div class="col-md-12" v-if="$store.state.user.roleid !== 3">
            <card>
              <i style="font-size: 30px" class="nc-icon bookmark-add"></i
              >&nbsp;&nbsp;
              <span style="font-size: 16pt"
                ><b>Bu yaxınlarda dərc edilmişdir</b></span
              >
              <l-table :data="recent">
                <template slot-scope="{ row }">
                  <td>
                    <h6>{{ row.orgname }}</h6>
                    {{ row.code + ". " + row.title }}
                    <br />
                    <small>{{
                      row.questioncount +
                      " sual " +
                      row.duration +
                      " dəq." +
                      " ərzində"
                    }}</small>
                    <br />
                    <small :class="{ 'text-danger': isduedate(row.validto) }"
                      >Active from {{ row.validfromstr }} to
                      {{ row.validtostr }}</small
                    >
                  </td>
                  <td>
                    <l-button
                      type="info"
                      block
                      outline
                      wide
                      @click="
                        $router.push({ path: '/account/start/' + row.id })
                      "
                    >
                      <span class="btn-label">
                        <i class="fa fa-exclamation"></i>
                      </span>
                      Let's try
                    </l-button>
                  </td>
                </template>
              </l-table>
              <div class="stats text-right" slot="footer">
                <l-button
                  wide
                  simple
                  link
                  @click="$router.push({ path: '/account/recent' })"
                  ><i class="fa fa-history"></i>See all</l-button
                >
              </div>
            </card>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-12">
            <card>
              <i style="font-size: 30px" class="nc-icon hat-3"></i>&nbsp;&nbsp;
              <span style="font-size: 16pt"><b>Taked exams</b></span>
              <l-table :data="taked">
                <template slot-scope="{ row }">
                  <td>
                    {{ row.code + ". " + row.title }}
                    <br />
                    {{ row.testtakername }}
                  </td>
                  <td>
                    {{ takeDate(row.enddate) }}
                  </td>
                  <td>
                    <span :class="statusStyle(row.result)">
                      <b>{{ row.resultname }}</b>
                    </span>
                  </td>
                  <td>
                    <l-button
                      tag="button"
                      type="secondary"
                      @click="
                        $router.push({ path: '/account/result/' + row.id })
                      "
                      outline
                    >
                      <i class="nc-icon preview"></i>
                    </l-button>
                  </td>
                </template>
              </l-table>
              <div class="stats text-right" slot="footer">
                <l-button
                  wide
                  simple
                  link
                  @click="$router.push({ path: '/account/taked' })"
                  ><i class="fa fa-history"></i>See all</l-button
                >
              </div>
            </card>
          </div>
          <div class="col-md-12" v-if="$store.state.user.roleid !== 3">
            <card>
              <i style="font-size: 30px" class="nc-icon certificate"></i
              >&nbsp;&nbsp;
              <span style="font-size: 16pt"><b>Certification</b></span>
              <l-table :data="certs">
                <template slot-scope="{ row }">
                  <td>
                    <h6>{{ row.orgname }}</h6>
                    {{ row.code + ". " + row.title }}
                    <br />
                    <small>{{
                      row.questioncount +
                      " sual " +
                      row.duration +
                      " dəq." +
                      " ərzində"
                    }}</small>
                    <br />
                    <small :class="{ 'text-danger': isduedate(row.validto) }"
                      >Active from {{ row.validfromstr }} to
                      {{ row.validtostr }}</small
                    >
                  </td>
                  <td>
                    <l-button type="info" block outline wide>
                      <span class="btn-label">
                        <i class="fa fa-exclamation"></i>
                      </span>
                      Let's try
                    </l-button>
                  </td>
                </template>
              </l-table>
              <div class="stats" slot="footer">
                <i class="fa fa-history"></i> See all
              </div>
            </card>
          </div>
        </div>
      </div>
    </div>
    <card v-else><h3>Knowledge Base is under construction</h3></card>
  </div>
</template>
<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import {
  ChartCard,
  StatsCard,
  Card,
  Table as LTable,
  Checkbox,
} from "src/components/index";
import {
  GetSharesRecent,
  GetSharesWithMe,
  GetTakedExams,
} from "../../services/know";
import { DateToString } from "../../services/helper";
export default {
  components: {
    Loading,
    Checkbox,
    Card,
    LTable,
    ChartCard,
    StatsCard,
  },
  props: {
    tab: {
      type: String,
      default: "test",
    },
  },
  data() {
    return {
      isLoading: false,
      recent: [],
      me: [],
      taked: [],
      certs: [],
    };
  },
  methods: {
    getRecent() {
      this.isLoading = true;
      GetSharesRecent(this.$store.state.user.id, 10).then((response) => {
        this.isLoading = false;
        if (response.data.result) {
          this.recent = response.data.result;
        } else {
          this.recent = [];
        }
      });
    },
    getMe() {
      this.isLoading = true;
      GetSharesWithMe(this.$store.state.user.id, "", 10).then((response) => {
        this.isLoading = false;
        if (response.data.result) {
          this.me = response.data.result;
        } else {
          this.me = [];
        }
      });
    },
    getTaked() {
      this.isLoading = true;
      GetTakedExams(this.$store.state.user.id, 10).then((response) => {
        this.isLoading = false;
        if (response.data.result) {
          this.taked = response.data.result;
        } else {
          this.taked = [];
        }
      });
    },
    isduedate(dt) {
      let d = new Date(dt);
      let n = new Date();
      if (parseInt((d - n) / (24 * 3600 * 1000)) > 7) return false;
      else return true;
    },
    statusStyle(result) {
      return { "text-success": result === 1, "text-danger": result === 0 };
    },
    takeDate(dt) {
      return DateToString(dt);
    },
  },
  mounted() {
    this.getRecent();
    this.getMe();
    this.getTaked();
  },
};
</script>
<style>
</style>
