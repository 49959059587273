<template>
  <div>
    <loading
      name="lp"
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
    <card>
      <div class="row">
        <div class="col-12">
          <l-button type="default" wide outline @click="onBack()">
            <i class="nc-icon left-arrow"></i>

            Ana səhifə
          </l-button>
        </div>
        <div class="col-12">
          <h3 class="mb-2">Paylaşımlar</h3>
        </div>
      </div>
      <div>
        <div
          class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
        >
          <div class="pl-lg-0">
            <button class="btn btn-fill btn-info btn-wd" @click="onNew()">
              Yeni paylaşma
            </button>
          </div>

          <el-input
            type="search"
            class="mb-3"
            style="width: 200px"
            placeholder="Search records"
            v-model="searchQuery"
            aria-controls="datatables"
          />
        </div>
        <div class="col-sm-12">
          <el-table
            stripe
            style="width: 100%"
            :data="
              shares.filter(
                (c) =>
                  c.title.toLowerCase().indexOf(searchQuery.toLowerCase()) >
                    -1 ||
                  c.code.toLowerCase().indexOf(searchQuery.toLowerCase()) >
                    -1 ||
                  c.email.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1
              )
            "
            border
          >
            <el-table-column
              :width="120"
              prop="sharedatestr"
              label="Paylaşma tarixi"
            >
            </el-table-column>
            <el-table-column label="Test">
              <template slot-scope="props">
                {{ props.row.code }}. {{ props.row.title }}
              </template>
            </el-table-column>
            <el-table-column
              :width="90"
              prop="duration"
              label="Müddət"
            ></el-table-column>
            <el-table-column
              :width="110"
              prop="validfromstr"
              label="Qüvvəyə minmə tarixi"
            >
            </el-table-column>
            <el-table-column
              :width="110"
              prop="validtostr"
              label="Qüvvədən düşmə tarixi"
            ></el-table-column>
            <el-table-column :width="50" label="Birdəfəlik">
              <template slot-scope="props">
                <div onclick="return false">
                  <l-checkbox
                    :checked="props.row.onetime === 1"
                    :readonly="true"
                  >
                  </l-checkbox>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="email" label="Email"></el-table-column>
            <el-table-column :width="120" label="Actions">
              <template slot-scope="props">
                <a
                  v-if="props.row.unregistered === 1"
                  v-tooltip.top-center="'Linki kopyala'"
                  class="btn-default btn-simple btn-link"
                  style="cursor: pointer"
                  @click="onCopy(props.row.id)"
                  ><i class="nc-icon url-1"></i> </a
                >&nbsp;&nbsp;
                <a
                  v-tooltip.top-center="'Statistika'"
                  class="btn-success btn-simple btn-link"
                  style="cursor: pointer"
                  @click="onStat(props.row.id)"
                  ><i class="nc-icon chart-bar-33"></i> </a
                >&nbsp;&nbsp;
                <a
                  v-tooltip.top-center="'Düzəliş'"
                  class="btn-warning btn-simple btn-link"
                  @click="onEdit(props.row)"
                  ><i class="nc-icon d-edit"></i> </a
                >&nbsp;&nbsp;
                <a
                  v-tooltip.top-center="'Ləğv'"
                  class="btn-danger btn-simple btn-link"
                  @click="onDelete(props.row)"
                  ><i class="nc-icon d-remove"></i
                ></a>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>

      <el-dialog center title="Paylaşma əməliyyatı" :visible.sync="shareModal">
        <ValidationObserver v-slot="{ handleSubmit }">
          <div class="row">
            <div class="col-lg-12" v-if="!share.id">
              <ValidationProvider
                name="tests"
                rules="required"
                v-slot="{ passed, failed }"
              >
                <fg-input
                  type="text"
                  label="Test"
                  placeholder="Test"
                  name="Test"
                  v-model="share.testid"
                  :error="failed ? 'The Test field is required' : null"
                  :hasSuccess="passed"
                >
                  <el-select
                    class="select-primary"
                    v-model="share.testid"
                    placeholder="Select test"
                    :error="failed ? 'The Kateqoriya field is required' : null"
                    :hasSuccess="passed"
                  >
                    <el-option
                      class="select-primary"
                      v-for="item in tests"
                      :key="item.id"
                      :label="item.code + '. ' + item.title"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </fg-input>
              </ValidationProvider>
            </div>

            <div class="col-lg-6">
              <ValidationProvider
                name="validfrom"
                rules="required"
                v-slot="{ passed, failed }"
              >
                <fg-input
                  label="Qüvvəyə minmə tarixi"
                  rules="required"
                  name="Qüvvəyə minmə tarixi"
                  :error="failed ? 'The Valid From field is required' : null"
                  :hasSuccess="passed"
                >
                  <el-date-picker
                    :error="failed ? 'The Valid From is required' : null"
                    :hasSuccess="passed"
                    @change="share.validto = null"
                    v-model="share.validfrom"
                    type="date"
                    placeholder="Date picker here"
                    :picker-options="optDateFrom"
                  >
                  </el-date-picker>
                </fg-input>
              </ValidationProvider>
            </div>

            <div class="col-lg-6">
              <ValidationProvider
                name="validto"
                rules="required"
                v-slot="{ passed, failed }"
              >
                <fg-input
                  label="Qüvvədən düşmə tarixi"
                  rules="required"
                  name="Qüvvədən düşmə tarixi"
                  :error="failed ? 'The Valid To field is required' : null"
                  :hasSuccess="passed"
                >
                  <el-date-picker
                    v-model="share.validto"
                    type="date"
                    placeholder="Date picker here"
                    :picker-options="optDateTo"
                    :error="failed ? 'The Valid To field is required' : null"
                    :hasSuccess="passed"
                  >
                  </el-date-picker>
                </fg-input>
              </ValidationProvider>
            </div>

            <div class="col-lg-3">
              <fg-input
                label="birdəfəlik"
                placeholder="birdəfəlik"
                name="isonetime"
              >
                <br />
                <el-switch v-model="onetime"></el-switch>
              </fg-input>
            </div>
            <div class="col-lg-9">
              <ValidationProvider
                name="email"
                rules="required"
                v-slot="{ passed, failed }"
              >
                <fg-input
                  type="text"
                  :error="
                    failed ? 'The Email or domain field is required' : null
                  "
                  :hasSuccess="passed"
                  label="Email or domain"
                  placeholder="Email or domain"
                  v-model="share.email"
                  name="Email or domain"
                ></fg-input>
              </ValidationProvider>
              <small
                >* - all registered users, *@company.com - to domain,
                bill@company.com - to person</small
              >
            </div>
            <div class="col-lg-12">
              <fg-input
                label="Qeydiyyatsız istifadəçilər üçün mövcuddur"
                placeholder="birdəfəlik"
                name="isonetime"
              >
                <br />
                <el-switch v-model="unregistered"></el-switch>
              </fg-input>
            </div>
          </div>

          <div slot="footer" class="dialog-footer text-center">
            <br />
            <button class="btn btn-danger" @click="shareModal = false">
              Xitam</button
            >&nbsp;
            <button class="btn btn-success" @click="handleSubmit(onSave)">
              Yadda saxla
            </button>
          </div>
        </ValidationObserver>
      </el-dialog>
    </card>
  </div>
</template>

<script>
import { DateToString, publicUrl } from "../../services/helper";
import Swal from "sweetalert2";
import VueClipboard from "vue-clipboard2";
import { ShareType } from "../../services/types";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import {
  Switch,
  DatePicker,
  Table,
  TableColumn,
  Select,
  Option,
  Dialog,
  MessageBox,
} from "element-ui";
import {
  GetTestsAll,
  GetShares,
  DeleteShare,
  UpdateShare,
  CreateShare,
} from "../../services/know";
import { Checkbox as LCheckbox } from "src/components/index";
export default {
  components: {
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dialog.name]: Dialog,
    [Switch.name]: Switch,
    Loading,
    LCheckbox,
  },
  data() {
    return {
      searchQuery: "",
      isLoading: false,
      shares: [],
      tests: [],
      shareModal: false,
      share: {},
      onetime: false,
      unregistered: false,
      optDateFrom: {
        disabledDate(date) {
          var dt = new Date();
          dt.setDate(dt.getDate() - 1);
          return date < dt;
        },
      },
      optDateTo: {
        disabledDate: this.disableToDate,
      },
    };
  },
  methods: {
    onCopy(id) {
      this.$copyText(publicUrl + id).then((e) => {
        if (e) {
          this.notifyVue("success", "Copied!");
        }
      });
    },
    disableToDate(date) {
      return date < this.share.validfrom;
    },
    onBack() {
      this.$router
        .push({
          path:
            this.$store.state.user.roleid === 1
              ? "/admin/main"
              : "/account/main",
        })
        .catch((e) => {});
    },
    onStat(id) {
      let routeData = this.$router.resolve({
        name: "Sessions",
        params: { id: id },
      });
      window.open(routeData.href, "_blank");
    },
    showSwal(type) {
      if (type === "warning") {
        return Swal.fire({
          title: "Are you sure?",
          text: "You will not be able to recover this record!",
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          confirmButtonClass: "btn btn-success btn-fill",
          cancelButtonClass: "btn btn-danger btn-fill",
          buttonsStyling: false,
        });
      }
    },
    onNew() {
      this.getTests();
      this.share = new ShareType();
      this.onetime = 0;
      this.share.uid = this.$store.state.user.id;
      this.shareModal = true;
    },
    onEdit(item) {
      this.share = Object.assign({}, item);
      this.onetime = this.share.onetime === 1;
      this.unregistered = this.share.unregistered === 1;
      this.shareModal = true;
    },
    onDelete(item) {
      this.showSwal("warning").then((del) => {
        if (del.isConfirmed) {
          this.isLoading = true;
          DeleteShare(item.id, this.$store.state.user.id).then((response) => {
            if (response.data.result) {
              this.isLoading = false;
              this.getShares();
              this.notifyVue("success", "Changes have been saved!");
            } else {
              this.isLoading = false;
              this.notifyVue("danger", response.data);
            }
          });
        }
      });
    },
    onSave() {
      this.isLoading = true;
      this.share.onetime = this.onetime ? 1 : 0;
      this.share.unregistered = this.unregistered ? 1 : 0;
      this.share.validfrom = DateToString(this.share.validfrom);
      this.share.validto = DateToString(this.share.validto);
      if (!this.share.id) {
        CreateShare(this.share).then((response) => {
          if (response.data.result) {
            this.isLoading = false;
            this.shareModal = false;
            this.getShares();
            this.notifyVue("success", "Changes have been saved!");
          } else {
            this.isLoading = false;
            this.notifyVue("danger", "Action failed!");
          }
        });
      } else {
        this.share.uid = this.$store.state.user.id;
        UpdateShare(this.share).then((response) => {
          if (response.data.result) {
            this.isLoading = false;
            this.shareModal = false;
            this.getShares();
            this.notifyVue("success", "Changes have been saved!");
          } else {
            this.isLoading = false;
            this.notifyVue("danger", "Action failed!");
          }
        });
      }
    },
    getShares() {
      this.isLoading = true;
      GetShares(this.$store.state.user.id).then((response) => {
        if (response.data.result) {
          this.isLoading = false;
          this.shares = response.data.result;
        } else {
          this.isLoading = false;
          this.shares = [];
        }
      });
    },
    getTests() {
      GetTestsAll(this.$store.state.user.id).then((response) => {
        if (response.data.result) {
          this.tests = response.data.result;
        }
      });
    },
  },
  mounted() {
    this.getShares();
  },
};
</script>

<style lang="scss">
.el-dialog {
  width: 50%;
}
@media (max-width: 800px) {
  .el-dialog {
    width: 90%;
  }
}
</style>