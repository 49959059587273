<template>
  <div>
    <loading
      name="lp"
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
    <ValidationObserver v-slot="{ handleSubmit }">
      <card v-if="test.id">
        <div class="row">
          <div class="col-lg-12 text-left">
            <l-button
              type="default"
              wide
              outline
              @click="$router.push({ name: 'AccountMain' })"
            >
              <i class="nc-icon left-arrow"></i>

              Ana səhifə
            </l-button>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-8 order-lg-1 order-1 overflow-auto" style="height: 35vh">
            <h3 class="mb-4">{{ test.code + ". " + test.title }}</h3>
            <h5 v-if="test.id"><small>by</small> {{ test.orgname }}</h5>
            <blockquote>
              <p>{{ test.description }}</p>
            </blockquote>
            
          </div>
          <div class="col-lg-4 order-lg-2 order-2">
            <ValidationProvider
              name="email"
              rules="required|email"
              v-slot="{ passed, failed }"
            >
              <fg-input
              :disabled="!enabled"
                type="email"
                :error="failed ? 'The Email field is required' : null"
                :hasSuccess="passed"
                label="Email address"
                name="email"
                v-model="email"
              >
              </fg-input>
            </ValidationProvider>
            <ValidationProvider
              name="fullname"
              rules="required"
              v-slot="{ passed, failed }"
            >
              <fg-input
              :disabled="!enabled"
                type="text"
                :error="failed ? 'The Name field is required' : null"
                :hasSuccess="passed"
                label="Your name"
                name="fullname"
                v-model="fullname"
              >
              </fg-input>
            </ValidationProvider>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 text-center">
            <h4>
              {{
                test.questioncount +
                " sual " +
                test.duration +
                " dəq." +
                " ərzində"
              }}
            </h4>
            <hr />
            <div class="text-center">
              <l-button type="success" size="lg" @click="handleSubmit(onStart)"
                >İmtahanı başla</l-button
              >
            </div>
          </div>
        </div>
      </card>
    </ValidationObserver>
    <card v-if="isnotaccessible">
      <div slot="header" class="row">
        <div class="col-lg order-lg-1 order-2">
          <h2>Test tapılmadı!</h2>
        </div>
        <div class="col-lg-auto order-lg-2 order-1">
          <l-button
            type="default"
            wide
            outline
            block
            @click="$router.push({ name: 'AccountMain' })"
          >
            <i class="nc-icon left-arrow"></i>

            Ana səhifə
          </l-button>
        </div>
      </div>
    </card>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Card from "../../components/Cards/Card.vue";
import { CreateSession, GetSharesStart } from "../../services/know";
import { ShareType } from "../../services/types";
export default {
  components: {
    Loading,
  },

  props: {
    id: String,
  },
  data() {
    return {
      isnotaccessible: false,
      isLoading: false,
      test: {},
      fullname: "",
      email: "",
      session: {},
      enabled: this.$store.state.user.roleid === 3
    };
  },
  methods: {
    onStart() {
      this.session.shareid = this.id;
      this.session.resemail = this.email;
      this.session.name = this.fullname;
      this.session.phone = "";
      this.session.uid = this.$store.state.user.id;
      this.isLoading = true;
      CreateSession(this.session).then((response) => {
        this.isLoading = false;
        if (response.data.result) {
          this.session.id = response.data.result;
          this.$router.push({ path: "/account/session/" + this.session.id });
        } else {
          this.notifyVue("danger", response.data);
        }
      });
    },
  },
  mounted() {
    if (this.$store.state.user.roleid !== 3) {
      this.fullname = this.$store.state.user.fullname;
      this.email = this.$store.state.user.email;
    }
    this.isLoading = true;
    GetSharesStart(this.id, this.$store.state.user.id).then((response) => {
      this.isLoading = false;
      if (response.data.result) {
        this.test = response.data.result;
      } else {
        this.isnotaccessible = true;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
</style>