<template>
  <div>
    <loading
      name="lp"
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
    <card>
      <div slot="header" class="row align-items-center">
        <div class="col-12">
          <h3 class="mb-0">
            {{ test.id ? test.code + ". " + test.title : "new" }}
          </h3>
        </div>
      </div>
      <div>
        <el-tabs v-model="activeTab" ref="tabs" @tab-click="onTabClicked">
          <el-tab-pane label="General" name="tabInfo">
            <ValidationObserver v-slot="{ handleSubmit }">
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-4">
                    <ValidationProvider
                      name="global"
                      rules="required"
                      v-slot="{ passed, failed }"
                    >
                      <fg-input
                        type="text"
                        label="Qlobal kateqoriya"
                        placeholder="Qlobal kateqoriya"
                        name="Global"
                        v-model="test.globalcategoryid"
                        :error="
                          failed
                            ? 'The Qlobal kateqoriya field is required'
                            : null
                        "
                        :hasSuccess="passed"
                      >
                        <el-select
                          class="select-primary"
                          v-model="test.globalcategoryid"
                          placeholder="Select category"
                          :error="
                            failed
                              ? 'The Qlobal kateqoriya field is required'
                              : null
                          "
                          :hasSuccess="passed"
                        >
                          <el-option
                            class="select-primary"
                            v-for="item in gcats"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                          ></el-option>
                        </el-select>
                      </fg-input>
                    </ValidationProvider>
                  </div>
                  <div class="col-4">
                    <ValidationProvider
                      name="category"
                      rules="required"
                      v-slot="{ passed, failed }"
                    >
                      <fg-input
                        type="text"
                        label="Kateqoriya"
                        placeholder="Kateqoriya"
                        name="Kateqoriya"
                        v-model="test.categoryid"
                        :error="
                          failed ? 'The Kateqoriya field is required' : null
                        "
                        :hasSuccess="passed"
                      >
                        <el-select
                          class="select-primary"
                          v-model="test.categoryid"
                          placeholder="Select category"
                          :error="
                            failed ? 'The Kateqoriya field is required' : null
                          "
                          :hasSuccess="passed"
                        >
                          <el-option
                            class="select-primary"
                            v-for="item in cats"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                          ></el-option>
                        </el-select>
                      </fg-input>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4">
                    <ValidationProvider
                      name="code"
                      rules="required"
                      v-slot="{ passed, failed }"
                    >
                      <fg-input
                        type="text"
                        :error="failed ? 'The Kod field is required' : null"
                        :hasSuccess="passed"
                        label="Kod"
                        placeholder="Kod"
                        v-model="test.code"
                        name="Kod"
                      ></fg-input>
                    </ValidationProvider>
                  </div>
                  <div class="col-lg-8">
                    <ValidationProvider
                      name="title"
                      rules="required"
                      v-slot="{ passed, failed }"
                    >
                      <fg-input
                        type="title"
                        label="Başlıq"
                        placeholder="Test title"
                        v-model="test.title"
                        :error="failed ? 'The Title field is required' : null"
                        :hasSuccess="passed"
                        name="title"
                      ></fg-input>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12">
                    <ValidationProvider
                      name="title"
                      rules="required"
                      v-slot="{ passed, failed }"
                    >
                      <fg-input
                        type="text"
                        label="Təsvir"
                        placeholder="Təsvir"
                        v-model="test.description"
                        :error="
                          failed ? 'The Təsvir field is required' : null
                        "
                        :hasSuccess="passed"
                        name="description"
                      ></fg-input>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4">
                    <fg-input
                      label="Müddət"
                      placeholder="Müddət"
                      name="Müddət"
                    >
                      <el-select
                        class="select-primary pagination-select"
                        v-model="test.duration"
                        placeholder="Müddət"
                      >
                        <el-option
                          class="select-primary"
                          :key="15"
                          :label="'15 min'"
                          :value="15"
                        ></el-option>
                        <el-option
                          class="select-primary"
                          :key="30"
                          :label="'30 min'"
                          :value="30"
                        ></el-option>
                        <el-option
                          class="select-primary"
                          :key="45"
                          :label="'45 min'"
                          :value="45"
                        ></el-option>
                        <el-option
                          class="select-primary"
                          :key="60"
                          :label="'60 min'"
                          :value="60"
                        ></el-option>
                        <el-option
                          class="select-primary"
                          :key="90"
                          :label="'90 min'"
                          :value="90"
                        ></el-option>
                        <el-option
                          class="select-primary"
                          :key="120"
                          :label="'120 min'"
                          :value="120"
                        ></el-option>
                      </el-select>
                    </fg-input>
                  </div>
                  <div class="col-lg-4">
                    <fg-input
                      label="Shuffle sections"
                      placeholder="Shuffle sections"
                      name="Shuffle sections"
                      ><br />
                      <el-switch v-model="isshuffle"></el-switch>
                    </fg-input>
                  </div>
                  <div class="col-lg-4">
                    <div v-if="test.id">
                      <fg-input
                        label="Active"
                        placeholder="Active"
                        name="Active"
                        ><br />
                        <el-switch v-model="isactive"></el-switch>
                      </fg-input>
                    </div>
                  </div>
                </div>
                <div>
                  <hr />
                  <div class="text-center">
                    <button
                      class="btn btn-primary"
                      @click="handleSubmit(onSave)"
                    >
                      Yadda saxla
                    </button>
                  </div>
                </div>
              </div>
            </ValidationObserver>
          </el-tab-pane>
          <el-tab-pane label="Sections" name="tabSection" :disabled="!test.id">
            <section-list
              :id="test.id"
              @change="sectionsUpdated"
            ></section-list>
          </el-tab-pane>
          <el-tab-pane
            label="Question & Answers"
            name="tabQA"
            :disabled="!test.id"
          >
            <question-list :id="test.id" ref="sections"></question-list>
          </el-tab-pane>
        </el-tabs>
      </div>
    </card>
  </div>
</template>

<script>
import {
  GetCategories,
  GetGlobalCategories,
  GetSections,
  GetTest,
  CreateTest,
  UpdateTest,
} from "../../services/know";
import SectionList from "./SectionList.vue";
import QuestionList from "./QuestionList.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import {
  Tabs,
  TabPane,
  Select,
  Option,
  Dialog,
  Switch,
  Table,
  TableColumn,
} from "element-ui";
export default {
  components: {
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
    [Select.name]: Select,
    [Option.name]: Option,
    [Dialog.name]: Dialog,
    [Switch.name]: Switch,
    Loading,
    SectionList,
    QuestionList,
  },
  props: {
    id: String,
  },
  data() {
    return {
      isLoading: false,
      activeTab: "tabInfo",
      cats: [],
      gcats: [],
      test: {
        id: this.id,
        duration: 30,
        shuffle: 0,
      },
      title: "",
      isshuffle: false,
      isactive: false,
      secupdated: false,
    };
  },
  methods: {
    onSave() {
      this.isLoading = true;
      this.test.shuffle = this.isshuffle ? 1 : 0;
      if (!this.test.id) {
        this.test.active = 0;
        this.test.id = "";
        this.test.uid = this.$store.state.user.id;
        CreateTest(this.test).then((response) => {
          if (response.data.result) {
            this.test.id = response.data.result;
            this.isLoading = false;
            this.notifyVue("success", "Changes have been saved!");
          } else {
            this.isLoading = false;
            this.notifyVue("danger", response.data);
          }
        });
      } else {
        this.test.active = this.isactive ? 1 : 0;
        UpdateTest(this.test).then((response) => {
          if (response.data.result !== undefined) {
            this.isLoading = false;
            this.notifyVue("success", "Changes have been saved!");
          } else {
            this.isLoading = false;
            this.notifyVue("danger", "Action failed!");
          }
        });
      }
    },
    getCategories() {
      GetCategories(this.$store.state.user.id).then((response) => {
        if (response.data.result) {
          this.cats = response.data.result;
          if (this.cats.length > 0) this.test.categoryid = this.cats[0].id;
        } else {
          this.isLoading = false;
          this.cats = [];
        }
      });
    },
    getGlobalCategories() {
      GetGlobalCategories().then((response) => {
        if (response.data.result) {
          this.gcats = response.data.result;
        } else {
          this.isLoading = false;
          this.gcats = [];
        }
      });
    },
    sectionsUpdated() {
      this.secupdated = true;
    },
    onTabClicked(tab, event) {
      if (!this.test.id || tab.name !== "tabQA" || !this.secupdated) return;

      this.$refs.sections.getSections();
      this.secupdated = false;
    },
  },
  mounted() {
    
    this.getGlobalCategories();
    this.getCategories();
    if (this.id) {
      this.isLoading = true;
      GetTest(this.id, this.$store.state.user.id).then((response) => {
        this.isLoading = false;
        if (response.data.result) {
          this.test = response.data.result;
          this.isshuffle = this.test.shuffle === 1;
          this.isactive = this.test.active === 1;
        } else {
          this.test.id = "-1";
          this.test.title = "Məlumat tapılmadı!";
          this.test.code = "EXC";
        }
      });
    }
  },
};
</script>

<style lang="scss" scoped>
</style>