import axios from "axios";
import NProgress from "nprogress";
import { api } from "./helper";
const client = axios.create({
  baseURL: api,
  withCredentials: false
});

client.interceptors.request.use(config => {
  NProgress.start();
  return config;
});

client.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    return Promise.reject(error.response);
  }
);

function GetStat(userid) {
  const params = {
    uid: userid
  };
  return client.get("stat.php", { params });
}

// Category
function GetCategories(userid) {
  const params = {
    uid: userid,
    action: "cats"
  };
  return client.get("categories.php", { params });
}

function GetGlobalCategories() {
  const params = {
    action: "gcats"
  };
  return client.get("categories.php", { params });
}

function UpdateCategory(cat) {
  cat.action = "edit";
  return client.put("categories.php", cat);
}

function DeleteCategory(id, userid) {
  const params = {
    uid: userid,
    id: id
  };
  return client.delete("categories.php", { params });
}

function CreateCategory(cat) {
  cat.action = "create";
  return client.post("categories.php", cat);
}

// Test
function GetTests(catid, userid) {
  const params = {
    categoryid: catid,
    uid: userid,
    action: "tests"
  };
  return client.get("tests.php", { params });
}

function GetTestsAll( userid) {
  const params = {
    uid: userid,
    action: "all"
  };
  return client.get("tests.php", { params });
}

function GetTest(id, userid) {
  const params = {
    id: id,
    uid: userid,
    action: "test"
  };
  return client.get("tests.php", { params });
}

function UpdateTest(t) {
  t.action = "edit";
  return client.put("tests.php", t);
}

function DeleteTest(id, userid) {
  const params = {
    uid: userid,
    id: id
  };
  return client.delete("tests.php", { params });
}

function CreateTest(t) {
  t.action = "create";
  return client.post("tests.php", t);
}

// Section
function GetSections(testid, userid) {
  const params = {
    testid: testid,
    uid: userid,
    action: "secs"
  };
  return client.get("sections.php", { params });
}

function UpdateSection(t) {
  t.action = "edit";
  return client.put("sections.php", t);
}

function DeleteSection(id, userid) {
  const params = {
    uid: userid,
    id: id
  };
  return client.delete("sections.php", { params });
}

function CreateSection(t) {
  t.action = "create";
  return client.post("sections.php", t);
}

// Question
function GetQuestions(sectionid, userid) {
  const params = {
    sectionid: sectionid,
    uid: userid,
    action: "quests"
  };
  return client.get("questions.php", { params });
}

function UpdateQuestion(t) {
  t.action = "edit";
  return client.put("questions.php", t);
}

function DeleteQuestion(id, userid) {
  const params = {
    uid: userid,
    id: id
  };
  return client.delete("questions.php", { params });
}

function CreateQuestion(t) {
  t.action = "create";
  return client.post("questions.php", t);
}

// Answer
function GetAnswers(id, userid) {
  const params = {
    id: id,
    uid: userid,
    action: "answer"
  };
  return client.get("answers.php", { params });
}

function UpdateAnswer(answer) {
  answer.action = "edit";
  return client.put("answers.php", answer);
}

function DeleteAnswer(id, userid) {
  const params = {
    uid: userid,
    id: id
  };
  return client.delete("answers.php", { params });
}

function CreateAnswer(answer) {
  answer.action = "create";
  return client.post("answers.php", answer);
}

// Share
function GetShares(userid) {
  const params = {
    uid: userid,
    action: "share"
  };
  return client.get("shares.php", { params });
}

function GetShare(id, userid) {
  const params = {
    id: id,
    uid: userid,
    action: "shareid"
  };
  return client.get("shares.php", { params });
}

function UpdateShare(share) {
  share.action = "edit";
  return client.put("shares.php", share);
}

function DeleteShare(id, userid) {
  const params = {
    uid: userid,
    id: id
  };
  return client.delete("shares.php", { params });
}

function CreateShare(share) {
  share.action = "create";
  return client.post("shares.php", share);
}

function GetSharesRecent(userid, cnt){
  const params = {
    uid: userid,
    cnt: cnt,
    action: "recent"
  };
  return client.get("shares.php", { params });
}

function GetSharesWithMe(userid, orgid, cnt){
  const params = {
    uid: userid,
    cnt: cnt,
    org: orgid,
    action: "me"
  };
  return client.get("shares.php", { params });
}

function GetSharesStart(shareid, userid){
  const params = {
    uid: userid,
    shareid: shareid,
    action: "start"
  };
  return client.get("shares.php", { params });
}

// Session

function CreateSession(session) {
  session.action = "create";
  return client.post("sessions.php", session);
}

function GetSessionTest(id, userid){
  const params = {
    uid: userid,
    id: id,
    action: "test"
  };
  return client.get("sessions.php", { params });
}

function GetSessionForTest(id, userid){
  const params = {
    uid: userid,
    id: id,
    action: "sessionfortest"
  };
  return client.get("sessions.php", { params });
}

function GetSession(id, userid){
  const params = {
    uid: userid,
    id: id,
    action: "session"
  };
  return client.get("sessions.php", { params });
}

function DeleteSession(id, userid) {
  const params = {
    uid: userid,
    id: id
  };
  return client.delete("sessions.php", { params });
}

function CompleteExam(session, answers) {
  let finish = {};
  finish.session = session;
  finish.answers = answers;
  finish.action = "complete";
  return client.put("sessions.php", finish);
}

function GetSessionSections(id, uid) {
  const params = {
    uid: uid,
    id: id,
    action: "sections"
  };
  return client.get("sessions.php", { params });
}

function PublishSession(id) {
  let pub = {};
  pub.id = id;
  pub.action = "publish";
  return client.put("sessions.php", pub);
}

function GetTakedExams(uid, cnt) {
  const params = {
    uid: uid,
    cnt: cnt,
    action: "taked"
  };
  return client.get("sessions.php", { params });
}

function GetShareSession(id, uid, datefrom, dateto) {
  const params = {
    id: id,
    uid: uid,
    datefrom: datefrom,
    dateto: dateto,
    action: "sharesession"
  };
  return client.get("sessions.php", { params });
}

function GetShareSessionStat(id, uid, datefrom, dateto) {
  const params = {
    id: id,
    uid: uid,
    datefrom: datefrom,
    dateto: dateto,
    action: "sharestat"
  };
  return client.get("sessions.php", { params });
}

function GetShareSessionBestScore(id, uid, datefrom, dateto) {
  const params = {
    id: id,
    uid: uid,
    datefrom: datefrom,
    dateto: dateto,
    action: "best"
  };
  return client.get("sessions.php", { params });
}

function GetShareSessionQuestionRate(id, uid, datefrom, dateto) {
  const params = {
    id: id,
    uid: uid,
    datefrom: datefrom,
    dateto: dateto,
    action: "qrate"
  };
  return client.get("sessions.php", { params });
}

function GetShareSessionQuestionReview(id, uid) {
  const params = {
    id: id,
    uid: uid,
    action: "review"
  };
  return client.get("sessions.php", { params });
}

export {
  GetGlobalCategories,
  GetCategories,
  DeleteCategory,
  UpdateCategory,
  CreateCategory,
  GetStat,
  GetTests,
  UpdateTest,
  DeleteTest,
  CreateTest,
  GetTest,
  GetTestsAll,
  GetSections,
  UpdateSection,
  DeleteSection,
  CreateSection,
  GetQuestions,
  UpdateQuestion,
  DeleteQuestion,
  CreateQuestion,
  GetAnswers,
  UpdateAnswer,
  CreateAnswer,
  DeleteAnswer,
  GetShares,
  UpdateShare,
  DeleteShare,
  CreateShare,
  GetSharesRecent,
  GetSharesWithMe,
  GetSharesStart,
  CreateSession,
  GetSessionTest,
  GetSession,
  DeleteSession,
  CompleteExam,
  GetSessionSections,
  GetSessionForTest,
  PublishSession,
  GetTakedExams,
  GetShareSession,
  GetShareSessionStat,
  GetShare,
  GetShareSessionBestScore,
  GetShareSessionQuestionRate,
  GetShareSessionQuestionReview
};
