var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('loading',{attrs:{"name":"lp","active":_vm.isLoading,"can-cancel":false,"is-full-page":true},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-3 text-center"},[_c('router-link',{staticClass:"dropdown-item",attrs:{"to":{ path: '/account/main/test' }}},[_c('i',{staticClass:"nc-icon check-list"}),_vm._v(" Testlər")])],1),_c('div',{staticClass:"col-lg-3 text-center"},[_c('router-link',{staticClass:"dropdown-item",attrs:{"to":{ path: '/account/main/kb' }}},[_c('i',{staticClass:"nc-icon books"}),_vm._v(" Bilik bazası")])],1),(_vm.$store.state.user.roleid === 3)?_c('div',{staticClass:"col-lg-6 text-right"},[_vm._v(" Hesab əldə etmək üçün "),_c('l-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.$router.push({
            path: '/login/Register/',
          })}}},[_vm._v(" Register ")])],1):_vm._e()]),_c('br'),(_vm.tab === 'test' || _vm.tab === '')?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('card',[_c('div',[_c('i',{staticClass:"nc-icon network-communication",staticStyle:{"font-size":"28px","padding-top":"10px"}}),_vm._v(" "),_c('span',{staticStyle:{"font-size":"16pt"}},[_c('b',[_vm._v("Sizinlə paylaşıldı")])])]),_c('l-table',{attrs:{"data":_vm.me},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('td',[_c('h6',[_vm._v(_vm._s(row.orgname))]),_vm._v(" "+_vm._s(row.code + ". " + row.title)+" "),_c('br'),_c('small',[_vm._v(_vm._s(row.questioncount + " sual " + row.duration + " dəq." + " ərzində"))]),_c('br'),_c('small',{class:{ 'text-danger': _vm.isduedate(row.validto) }},[_vm._v("Active from "+_vm._s(row.validfromstr)+" to "+_vm._s(row.validtostr))])]),_c('td',[_c('l-button',{attrs:{"type":"primary","block":"","outline":"","wide":""},on:{"click":function($event){return _vm.$router.push({ path: '/account/start/' + row.id })}}},[_c('span',{staticClass:"btn-label"},[_c('i',{staticClass:"fa fa-exclamation"})]),_vm._v(" Let's try ")])],1)]}}],null,false,400725257)}),_c('div',{staticClass:"stats text-right",attrs:{"slot":"footer"},slot:"footer"},[_c('l-button',{attrs:{"wide":"","simple":"","link":""},on:{"click":function($event){return _vm.$router.push({ path: '/account/shared' })}}},[_c('i',{staticClass:"fa fa-history"}),_vm._v("See all")])],1)],1)],1),(_vm.$store.state.user.roleid !== 3)?_c('div',{staticClass:"col-md-12"},[_c('card',[_c('i',{staticClass:"nc-icon bookmark-add",staticStyle:{"font-size":"30px"}}),_vm._v(" "),_c('span',{staticStyle:{"font-size":"16pt"}},[_c('b',[_vm._v("Bu yaxınlarda dərc edilmişdir")])]),_c('l-table',{attrs:{"data":_vm.recent},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('td',[_c('h6',[_vm._v(_vm._s(row.orgname))]),_vm._v(" "+_vm._s(row.code + ". " + row.title)+" "),_c('br'),_c('small',[_vm._v(_vm._s(row.questioncount + " sual " + row.duration + " dəq." + " ərzində"))]),_c('br'),_c('small',{class:{ 'text-danger': _vm.isduedate(row.validto) }},[_vm._v("Active from "+_vm._s(row.validfromstr)+" to "+_vm._s(row.validtostr))])]),_c('td',[_c('l-button',{attrs:{"type":"info","block":"","outline":"","wide":""},on:{"click":function($event){return _vm.$router.push({ path: '/account/start/' + row.id })}}},[_c('span',{staticClass:"btn-label"},[_c('i',{staticClass:"fa fa-exclamation"})]),_vm._v(" Let's try ")])],1)]}}],null,false,238649163)}),_c('div',{staticClass:"stats text-right",attrs:{"slot":"footer"},slot:"footer"},[_c('l-button',{attrs:{"wide":"","simple":"","link":""},on:{"click":function($event){return _vm.$router.push({ path: '/account/recent' })}}},[_c('i',{staticClass:"fa fa-history"}),_vm._v("See all")])],1)],1)],1):_vm._e()])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('card',[_c('i',{staticClass:"nc-icon hat-3",staticStyle:{"font-size":"30px"}}),_vm._v(" "),_c('span',{staticStyle:{"font-size":"16pt"}},[_c('b',[_vm._v("Taked exams")])]),_c('l-table',{attrs:{"data":_vm.taked},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('td',[_vm._v(" "+_vm._s(row.code + ". " + row.title)+" "),_c('br'),_vm._v(" "+_vm._s(row.testtakername)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.takeDate(row.enddate))+" ")]),_c('td',[_c('span',{class:_vm.statusStyle(row.result)},[_c('b',[_vm._v(_vm._s(row.resultname))])])]),_c('td',[_c('l-button',{attrs:{"tag":"button","type":"secondary","outline":""},on:{"click":function($event){return _vm.$router.push({ path: '/account/result/' + row.id })}}},[_c('i',{staticClass:"nc-icon preview"})])],1)]}}],null,false,1686395752)}),_c('div',{staticClass:"stats text-right",attrs:{"slot":"footer"},slot:"footer"},[_c('l-button',{attrs:{"wide":"","simple":"","link":""},on:{"click":function($event){return _vm.$router.push({ path: '/account/taked' })}}},[_c('i',{staticClass:"fa fa-history"}),_vm._v("See all")])],1)],1)],1),(_vm.$store.state.user.roleid !== 3)?_c('div',{staticClass:"col-md-12"},[_c('card',[_c('i',{staticClass:"nc-icon certificate",staticStyle:{"font-size":"30px"}}),_vm._v(" "),_c('span',{staticStyle:{"font-size":"16pt"}},[_c('b',[_vm._v("Certification")])]),_c('l-table',{attrs:{"data":_vm.certs},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('td',[_c('h6',[_vm._v(_vm._s(row.orgname))]),_vm._v(" "+_vm._s(row.code + ". " + row.title)+" "),_c('br'),_c('small',[_vm._v(_vm._s(row.questioncount + " sual " + row.duration + " dəq." + " ərzində"))]),_c('br'),_c('small',{class:{ 'text-danger': _vm.isduedate(row.validto) }},[_vm._v("Active from "+_vm._s(row.validfromstr)+" to "+_vm._s(row.validtostr))])]),_c('td',[_c('l-button',{attrs:{"type":"info","block":"","outline":"","wide":""}},[_c('span',{staticClass:"btn-label"},[_c('i',{staticClass:"fa fa-exclamation"})]),_vm._v(" Let's try ")])],1)]}}],null,false,181610602)}),_c('div',{staticClass:"stats",attrs:{"slot":"footer"},slot:"footer"},[_c('i',{staticClass:"fa fa-history"}),_vm._v(" See all ")])],1)],1):_vm._e()])])]):_c('card',[_c('h3',[_vm._v("Knowledge Base is under construction")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }