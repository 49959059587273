<template>
  <div>
    <loading
      name="lp"
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
    <div
      class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
    >
      <div class="pl-lg-0">
        <button class="btn btn-fill btn-info btn-wd" @click="onNew()">
          Yeni hissə
        </button>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <l-button type="info" wide outline @click="onRefresh()">
          Yenilə
        </l-button>
      </div>

      <el-input
        type="search"
        class="mb-3"
        style="width: 200px"
        placeholder="Axtarış"
        v-model="searchQuery"
        aria-controls="datatables"
      />
    </div>
    <div class="col-sm-12">
      <el-table stripe style="width: 100%" :data="secs" border>
        <el-table-column type="expand">
          <template slot-scope="props">
            <p>Təsvir:</p>
            <span v-html="parseHtml(props.row.description)"></span>
            <p><br />İstinad:</p>
            <span v-html="parseHtml(props.row.reference)"></span>
          </template>
        </el-table-column>
        <el-table-column :width="60" prop="orderindex" label="#">
        </el-table-column>
        <el-table-column prop="name" label="Name"> </el-table-column>
        <el-table-column
          :width="120"
          prop="questionsinexam"
          label="Sual sayı"
        >
        </el-table-column>
        <el-table-column :width="100" prop="scoremin" label="Keçid balı, %">
        </el-table-column>
        <el-table-column :width="80" label="Shuffle">
          <template slot-scope="props">
            <div onclick="return false">
              <l-checkbox :checked="props.row.shuffle === 1" :readonly="true">
              </l-checkbox>
            </div>
          </template>
        </el-table-column>

        <el-table-column :width="80" label="Active">
          <template slot-scope="props">
            <div onclick="return false">
              <l-checkbox :checked="props.row.active === 1"> </l-checkbox>
            </div>
          </template>
        </el-table-column>
        <el-table-column :width="60" label="Valid">
          <template slot-scope="props">
            <i
              v-tooltip.top-center="props.row.statusname"
              :class="{
                'text-danger nc-icon t-warning': props.row.status !== 1,
                'text-success nc-icon check-single': props.row.status === 1,
              }"
            />
          </template>
        </el-table-column>
        <el-table-column :width="120" label="Actions">
          <template slot-scope="props">
            <a
              v-tooltip.top-center="'Edit'"
              class="btn-warning btn-simple btn-link"
              @click="onEdit(props.row)"
              ><i class="nc-icon d-edit"></i> </a
            >&nbsp;&nbsp;
            <a
              v-tooltip.top-center="'Delete'"
              class="btn-danger btn-simple btn-link"
              @click="onDelete(props.row)"
              ><i class="nc-icon d-remove"></i
            ></a>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog center title="Əməliyyat" :visible.sync="secModal">
      <ValidationObserver v-slot="{ handleSubmit }">
        <div class="row">
          <div class="col-lg-8">
            <ValidationProvider
              name="name"
              rules="required"
              v-slot="{ passed, failed }"
            >
              <fg-input
                type="text"
                :error="failed ? 'The Section Name field is required' : null"
                :hasSuccess="passed"
                label="Bölmənin adı"
                placeholder="Bölmənin adı"
                v-model="sec.name"
                name="Section name"
              ></fg-input>
            </ValidationProvider>
          </div>
          <div class="col-lg-4">
            <ValidationProvider
              name="qcount"
              rules="required"
              v-slot="{ passed, failed }"
            >
              <fg-input
                :min="1"
                :max="999"
                type="number"
                :error="failed ? 'The Question count field is required' : null"
                :hasSuccess="passed"
                label="İmtahanda sualların sayı"
                placeholder="İmtahanda sualların sayı"
                v-model="sec.questionsinexam"
                name="İmtahanda sualların sayı"
              ></fg-input>
            </ValidationProvider>
          </div>
          <div class="col-lg-12">
            <fg-input label="Təsvir">
              <textarea
                v-model="sec.description"
                class="form-control"
                placeholder="Kateqoriya decription"
                rows="3"
              ></textarea>
            </fg-input>
          </div>
          <div class="col-lg-3">
            <fg-input
              :min="1"
              :max="100"
              type="number"
              label="Keçid balı, %"
              placeholder="Bal"
              v-model="sec.scoremin"
              name="Score"
            ></fg-input>
          </div>
          <div class="col-lg-3">
            <fg-input
              label="Shuffle tests"
              placeholder="Shuffle tests"
              name="Shuffle tests"
              ><br />
              <el-switch v-model="isshuffle"></el-switch>
            </fg-input>
          </div>
          <div class="col-lg-3">
            <div v-if="sec.id">
              <fg-input label="Active" placeholder="Active" name="active">
                <br />
                <el-switch v-model="isactive"></el-switch>
              </fg-input>
            </div>
          </div>
          <div class="col-lg-3">
            <ValidationProvider
              name="orderindex"
              rules="required"
              v-slot="{ passed, failed }"
            >
              <fg-input
                :min="0"
                :max="99"
                type="number"
                :error="failed ? 'The Order index field is required' : null"
                :hasSuccess="passed"
                label="Sıra nömrəsi"
                placeholder="Sıra nömrəsi"
                v-model="sec.orderindex"
                name="Order index"
              ></fg-input>
            </ValidationProvider>
          </div>
          <div class="col-lg-12">
            <fg-input label="İstinad">
              <textarea
                v-model="sec.reference"
                class="form-control"
                placeholder="İstinad"
                rows="3"
              ></textarea>
            </fg-input>
          </div>
        </div>

        <div slot="footer" class="dialog-footer text-center">
          <button class="btn btn-danger" @click="secModal = false">
            Xitam</button
          >&nbsp;
          <button class="btn btn-success" @click="handleSubmit(onSave)">
            Yadda saxla
          </button>
        </div>
      </ValidationObserver>
    </el-dialog>
  </div>
</template>

<script>
import { ShowLink, HtmlNewLine } from "../../services/helper";
import Swal from "sweetalert2";
import {
  GetSections,
  CreateSection,
  UpdateSection,
  DeleteSection,
} from "../../services/know";
import { SectionType } from "../../services/types";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { Select, Option, Dialog, Switch, Table, TableColumn } from "element-ui";
import { Checkbox as LCheckbox } from "src/components/index";
export default {
  name: "section-list",
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dialog.name]: Dialog,
    [Switch.name]: Switch,
    Loading,
    LCheckbox,
  },
  props: {
    id: String,
  },
  data() {
    return {
      isshuffle: false,
      isactive: false,
      searchQuery: "",
      isLoading: false,
      secs: [],
      sec: {
        id: "",
        orderindex: 1,
      },
      secModal: false,
    };
  },
  methods: {
    onRefresh() {
      this.getSections();
    },
    getSections() {
      if (!this.id) return;
      this.isLoading = true;
      GetSections(this.id, this.$store.state.user.id).then((response) => {
        this.isLoading = false;
        if (response.data.result) {
          this.secs = response.data.result;
        } else {
          this.secs = [];
          if (response.data) this.notifyVue("danger", response.data);
        }
      });
    },
    showSwal(type) {
      if (type === "warning") {
        return Swal.fire({
          title: "Are you sure?",
          text: "You will not be able to recover this record!",
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          confirmButtonClass: "btn btn-success btn-fill",
          cancelButtonClass: "btn btn-danger btn-fill",
          buttonsStyling: false,
        });
      }
    },
    onNew() {
      this.sec = new SectionType();
      this.sec.uid = this.$store.state.user.id;
      this.sec.testid = this.id;
      this.secModal = true;
    },
    onEdit(item) {
      this.sec = Object.assign({}, item);
      this.isshuffle = this.sec.shuffle === 1;
      this.isactive = this.sec.active === 1;
      this.secModal = true;
    },
    onDelete(item) {
      this.showSwal("warning").then((del) => {
        if (del.isConfirmed) {
          this.isLoading = true;
          DeleteSection(item.id, this.$store.state.user.id).then((response) => {
            if (response.data.result) {
              this.isLoading = false;
              this.getSections();
              this.notifyVue("success", "Changes have been saved!");
              this.$emit('change');
            } else {
              this.isLoading = false;
              this.notifyVue("danger", response.data);
            }
          });
        }
      });
    },
    onSave() {
      this.isLoading = true;
      this.sec.shuffle = this.isshuffle ? 1 : 0;
      this.sec.uid = this.$store.state.user.id;
      if (!this.sec.id) {
        CreateSection(this.sec).then((response) => {
          if (response.data.result) {
            this.isLoading = false;
            this.secModal = false;
            this.getSections();
            this.notifyVue("success", "Changes have been saved!");
          } else {
            this.isLoading = false;
            this.notifyVue("danger", "Action failed!");
          }
        });
      } else {
        this.sec.active = this.isactive ? 1 : 0;
        UpdateSection(this.sec).then((response) => {
          if (response.data.result) {
            this.isLoading = false;
            this.secModal = false;
            this.getSections();
            this.notifyVue("success", "Changes have been saved!");
          } else {
            this.isLoading = false;
            this.notifyVue("danger", "Action failed!");
          }
        });
      }
      this.$emit('change');
    },
    parseHtml(item) {
      let res = ShowLink(HtmlNewLine(item));
      return res;
    },
  },
  mounted() {
    this.getSections();
  },
};
</script>

<style lang="scss" scoped>
</style>