<template>
  <div class="dimScreen">
    <notifications></notifications>
    <loading
      name="lp"
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
    <card v-if="sessionNotFound">
      <div slot="header">
        <div class="row">
          <div class="col-12">
            <h3>Sessiya tapılmadı. Əsas səhifəyə keçin və yenidən cəhd edin.</h3>
          </div>
          <div class="col-12">
            <l-button
              type="default"
              wide
              outline
              @click="$router.push({ name: 'AccountMain' })"
            >
              <i class="nc-icon left-arrow"></i>

              Ana səhifə
            </l-button>
          </div>
        </div>
      </div>
    </card>
    <div class="container" v-if="!sessionNotFound && this.questions.length > 0">
      <div style="background-color: white" class="border rounded p-2 shadow-sm">
        <div
          class="tools p-2 bg-info rounded mb-2 text-white d-flex justify-content-between align-items-center"
        >
          <div style="width: 100%" class="row">
            <div class="col-lg-4">
              <small
                >Sual #: {{ questionindex + 1 }} /
                {{ questions.length }}</small
              ><br />
              <span>Hissə: {{ current.question.sectionname }}</span>
            </div>
            <div class="col-lg-4 text-center">
              <vue-countdown-timer
                @start_callback="startCallBack('event started')"
                @end_callback="endCallBack('event ended')"
                :start-time="startDate"
                :end-time="endDate"
                :interval="1000"
                label-position="begin"
                :day-txt="''"
                :hour-txt="' :'"
                :minutes-txt="' :'"
                :seconds-txt="''"
              >
                <template slot="countdown" slot-scope="scope">
                  <span style="font-size: 18px">
                    <span>{{ scope.props.hours }}</span
                    >{{ scope.props.hourTxt }}
                    <span>{{ scope.props.minutes }}</span
                    >{{ scope.props.minutesTxt }}
                    <span>{{ scope.props.seconds }}</span
                    >{{ scope.props.secondsTxt }}
                  </span>
                </template>
              </vue-countdown-timer>
            </div>
            <div class="col-lg-4 controll">
              <l-button
                type="warning"
                @click="onPrevious()"
                style="height: 38px"
                :disabled="questionindex < 1"
              >
                <i class="nc-icon arrow-w"></i>
              </l-button>
              &nbsp;
              <l-button
                type="warning"
                @click="onNext()"
                :disabled="questionindex === questions.length - 1"
              >
                Növbəti
                <i class="nc-icon arrow-e"></i>
              </l-button>
              &nbsp;
              <l-button type="success" @click="onFinish">
                <i class="nc-icon chequered-flag"></i>
                Bitir
              </l-button>
              &nbsp;

              <!-- <button type="button" class="btn btn-ligh" @click="onReview"><i class="nc-icon preview"></i></button> -->
              <l-button
                tag="button"
                type="secondary"
                @click="onReview"
                outline
                style="height: 38px; background-color: lightgray"
              >
                <i class="nc-icon preview"></i>
              </l-button>
            </div>
          </div>
        </div>
        <h5
          style="font-weight: 600"
          class="question px-2 mt-4 mb-4 unselectable"
        >
          {{ current.question.question }}
          <span
              v-if="current.question.answertype === 2"
              style="font-size: 12px;"
              class="text-danger"
              ><i class="nc-icon checkbox-btn-checked"></i> Çoxsaylı 
              seçim</span
            >
        </h5>
        <div class="row">
          <div class="col-lg-auto" style="padding-left: 30px">
            <small
              v-if="current.question.notes"
              class="text-muted"
              
              ><i>* {{ current.question.notes }}</i></small
            >
          </div>
        </div>

        <div class="row">
          <div :class="{ 'col-lg-8': picture, 'col-lg-12': !picture }">
            <div
              style="padding-left: 10px"
              class="answers justify-content-between align-items-start"
            >
              <div
                class="row"
                v-for="answer in current.answers"
                :key="answer.id"
              >
                <div class="col-12" v-if="current.question.answertype === 1">
                  <radio
                    @click="onRadioClick(answer.id)"
                    :label="answer.answer"
                    v-model="answer.selected"
                    >{{ answer.answer }}</radio
                  >
                </div>
                <div class="col-12" v-if="current.question.answertype === 2">
                  <checkbox v-model="answer.answerstatus">{{
                    answer.answer
                  }}</checkbox>
                </div>
                <div class="col-12" v-if="current.question.answertype === 3">
                  <fg-input
                    type="text"
                    placeholder="Your answer"
                    v-model="answer.answertext"
                  ></fg-input>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4" v-if="picture">
            <img width="100%" :src="picture" style="height: 200px" />
          </div>
        </div>
      </div>
    </div>

    <!-- <card v-if="false">
      <div slot="header">
        <div class="row">
          <div class="col-10">
            <h4>
              Test code and title: {{ session.code }}. {{ session.title }}
            </h4>
            İmtahan verən: {{ session.testtakername }}
          </div>
          <div class="col-2">
            <vue-countdown-timer
              @start_callback="startCallBack('event started')"
              @end_callback="endCallBack('event ended')"
              :start-time="startDate"
              :end-time="endDate"
              :interval="1000"
              label-position="begin"
              :day-txt="''"
              :hour-txt="' :'"
              :minutes-txt="' :'"
              :seconds-txt="''"
            >
              <template slot="countdown" slot-scope="scope">
                <h4>
                  <span>{{ scope.props.hours }}</span
                  >{{ scope.props.hourTxt }}
                  <span>{{ scope.props.minutes }}</span
                  >{{ scope.props.minutesTxt }}
                  <span>{{ scope.props.seconds }}</span
                  >{{ scope.props.secondsTxt }}
                </h4>
              </template>
            </vue-countdown-timer>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          Sualların sayı: {{ session.questioncount }}
        </div>
        <div class="col-12">Hissə: {{ current.section.name }}</div>
        <div class="col-12">
          Sual #: {{ questionindex + 1 }} /
          {{ current.section.questioncount }}
        </div>
      </div>

      <div style="min-height: 200px">
        <div class="row">
          <div :class="{ 'col-lg-8': picture, 'col-lg-12': !picture }">
            {{ current.question.question }}
            <small v-if="current.question.notes" class="text-muted"
              ><br /><i>* {{ current.question.notes }}</i></small
            >

            <span
              v-if="current.question.answertype === 2"
              style="font-size: 12px"
              class="text-danger"
              ><br /><i class="nc-icon checkbox-btn-checked"></i> Multiple
              choice</span
            >
            <div style="margin-top: 5px">
              <div
                class="row"
                v-for="answer in current.answers"
                :key="answer.id"
              >
                <div class="col-12" v-if="current.question.answertype === 1">
                  <radio
                    @click="onRadioClick(answer.id)"
                    :label="answer.answer"
                    v-model="answer.selected"
                    >{{ answer.answer }}</radio
                  >
                </div>
                <div class="col-12" v-if="current.question.answertype === 2">
                  <checkbox v-model="answer.answerstatus">{{
                    answer.answer
                  }}</checkbox>
                </div>
                <div class="col-12" v-if="current.question.answertype === 3">
                  <fg-input
                    type="text"
                    placeholder="Your answer"
                    v-model="answer.answertext"
                  ></fg-input>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4" v-if="picture">
            <img width="100%" :src="picture" style="height: 200px" />
          </div>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-lg-2">
          <l-button type="primary" wide block @click="onPrevious()">
            <i class="nc-icon arrow-w"></i>
            Previous
          </l-button>
        </div>
        <div class="col-lg-8 text-center">
          <l-button type="success" wide @click="onReview">
            <i class="nc-icon preview"></i>
            Review & Bitir
          </l-button>
        </div>
        <div class="col-lg-2">
          <l-button type="primary" wide block @click="onNext()">
            Növbəti
            <i class="nc-icon arrow-e"></i>
          </l-button>
        </div>
      </div>
    </card> -->

    <el-dialog
      :append-to-body="true"
      center
      title="Review"
      top="1vh"
      :visible.sync="reviewModal"
      width="90%"
      :lock-scroll="false"
    >
      <div>
        <div class="row">
          <div class="col-lg-12 text-center">
            <label class="q-report">Ümumi: {{ qreport.total }}</label
            >, <label class="q-report">Yekunlaşdırılıb: {{ qreport.done }}</label
            >,
            <label class="q-report">Cavablandırılıb: {{ qreport.skipped }}</label>
          </div>
        </div>
        <div style="height: 70vh" class="row overflow-auto">
          <div class="col-lg-12">
            <l-table :data="questions">
              <template slot-scope="{ row }">
                <td width="50px">
                  <i :class="questionStatus(row.id)"></i>
                </td>
                <td>
                  <a
                    class="unselectable"
                    style="cursor: pointer; color: black"
                    :key="row.id"
                    :id="row.id"
                    :name="row.id"
                    @click.prevent="onMove(row.id)"
                    >{{ row.orderindex + ". " + row.question }}</a
                  >
                </td>
              </template>
            </l-table>
            <!-- <div
              :key="quest.id"
              v-for="quest in questions"
              class="row review-quest-div"
            >
              <div class="col-11">
              </div>
            </div> -->
          </div>
        </div>
        <!-- <div class="row">
          <div class="col-lg-4" v-for="i in 3" :key="i">
            <div
              :key="idx"
              v-for="idx in reviewRowsCount"
              class="review-quest-div"
            >
              <div v-if="questions[reviewIndex(idx, i)]">
                <h6 v-if="questions[reviewIndex(idx, i)].orderindex === 1">
                  {{ sectionName(questions[reviewIndex(idx, i)].sectionid) }}
                </h6>
                <a
                  style="cursor: pointer"
                  :key="questions[reviewIndex(idx, i)].id"
                  :id="questions[reviewIndex(idx, i)].id"
                  :name="questions[reviewIndex(idx, i)].id"
                  @click.prevent="onMove(questions[reviewIndex(idx, i)].id)"
                  :class="questionStatus(questions[reviewIndex(idx, i)].id)"
                  >{{
                    questions[reviewIndex(idx, i)].orderindex +
                    ". " +
                    questions[reviewIndex(idx, i)].question
                  }}</a
                >
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  DateTimeToString,
  ShowLink,
  HtmlNewLine,
  uploadUrl,
  fileUrl,
} from "../../services/helper";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Card from "../../components/Cards/Card.vue";
import {
  GetSessionForTest,
  GetSessionTest,
  DeleteSession,
  CompleteExam,
} from "../../services/know";
import { Dialog } from "element-ui";
import { Checkbox, Radio, Table as LTable } from "src/components/index";
import Swal from "sweetalert2";
export default {
  components: {
    [Dialog.name]: Dialog,
    Loading,
    Checkbox,
    Radio,
    LTable,
  },

  props: {
    id: String,
  },
  computed: {
    uploadurl() {
      return uploadUrl;
    },
    fileurl() {
      return fileUrl;
    },
    picture() {
      return !this.current.question.picture
        ? ""
        : this.fileurl + this.current.question.picture;
    },
    // reviewRowsCount() {
    //   let fl = Math.floor(this.questions.length / 3);
    //   return Math.abs(fl - this.questions.length / 3.0) > 0 ? fl + 1 : fl;
    // },
  },
  data() {
    return {
      isLoading: false,
      reviewModal: false,
      totalquestion: 0,
      questionindex: 0,
      current: {
        question: {},
        answers: [],
      },
      isLoading: false,
      session: {},
      sections: [],
      questions: [],
      answers: [],
      startDate: new Date(),
      endDate: new Date(),
      qreport: {},
      sessionNotFound: false,
    };
  },
  methods: {
    showSwal(type, msg) {
      if (type === "warning") {
        return Swal.fire({
          title: "Are you sure?",
          text: msg,
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          confirmButtonClass: "btn btn-success btn-fill",
          cancelButtonClass: "btn btn-danger btn-fill",
          buttonsStyling: false,
        });
      } else if (type === "auto-close") {
        Swal.fire({
          title: "Time is up!",
          text:
            "Sorry, exam has finished! Now you will proceede to result page.",
          timer: 3000,
          showConfirmButton: false,
        }).then(this.onEnd());
      }
    },
    startCallBack: function (x) {
    },
    endCallBack: function (x) {
      this.showSwal("auto-close", "");
    },
    onPrevious() {
      this.lastquestion = false;
      if (this.questionindex === 0) {
        return;
      } else {
        this.questionindex -= 1;
      }
      this.setCurrentQuestion();
    },
    onNext() {
      if (this.questionindex === this.questions.length - 1) {
        return;
      } else {
        this.questionindex += 1;
      }
      this.setCurrentQuestion();
    },
    setCurrentQuestion() {
      this.current.question = this.questions.filter(
        (c) => c.orderindex === this.questionindex + 1
      )[0];
      this.current.answers = this.answers.filter(
        (c) => c.questionid === this.current.question.id
      );
    },
    onMove(id) {
      let q = this.questions.filter((c) => c.id === id)[0];
      this.questionindex = q.orderindex - 1;
      this.sectionindex =
        this.sections.filter((c) => c.id === q.sectionid)[0].orderindex - 1;
      this.setCurrentQuestion();
      this.reviewModal = false;
    },
    onRadioClick(id) {
      this.current.answers.forEach(function (item) {
        item.answerstatus = item.id === id;
        if (item.id !== id) item.selected = "";
      });
    },
    sectionName(sectionId) {
      return this.sections.filter((c) => c.id === sectionId)[0].name;
    },
    // reviewIndex(idx, i) {
    //   return idx - 1 + (i - 1) * this.reviewRowsCount;
    // },
    // rowLimit(idx) {
    //   for (var i = 1; i <= 3; i++) {
    //     if (this.reviewIndex(idx, i) >= this.questions.length) {
    //       return i - 1;
    //     }
    //   }
    //   return 3;
    // },
    questionStatus(id) {
      if (
        this.answers.filter(
          (c) =>
            c.questionid === id &&
            (c.answerstatus ||
              (c.answertext !== null && c.answertext.trim() != ""))
        ).length > 0
      )
        return "nc-icon check-single text-success";
      else return "nc-icon question-mark text-warning";
    },

    questReport() {
      const status = (value, index, self) => {
        return (
          this.answers.filter(
            (t) =>
              (t.questionid === value.id) &
              (t.answerstatus ||
                (t.answertext !== null && t.answertext.trim() != ""))
          ).length > 0
        );
      };
      let res = { total: this.questions.length, done: 0, skipped: 0 };
      res.done = this.questions.filter(status).length;
      res.skipped = res.total - res.done;
      return res;
    },
    onReview() {
      this.qreport = this.questReport();
      this.reviewModal = true;
    },
    onFinish() {
      let res = this.questReport();
      let msg =
        res.skipped > 0
          ? "You have " +
            res.skipped +
            " unanswered question" +
            (res.skipped > 1 ? "s" : "")
          : "Everything looks good!";
      this.showSwal("warning", msg).then((finish) => {
        if (finish.isConfirmed) {
          this.onEnd();
        }
      });
    },
    onEnd() {
      this.isLoading = true;
      CompleteExam(this.session, this.answers).then((response) => {
        this.isLoading = false;
        if (response.data.result) {
          this.$router.push({ path: "/account/result/" + this.id });
        } else {
        }
      });
    },
  },
  mounted() {
    this.sessionNotFound = false;
    this.isLoading = true;
    GetSessionForTest(this.id, this.$store.state.user.id).then((r1) => {
      if (r1.data.result) {
        this.session = r1.data.result;
        GetSessionTest(this.id, this.$store.state.user.id).then((r2) => {
          this.isLoading = false;
          if (r2.data.result) {
            this.sections = r2.data.result.sections;
            this.questions = r2.data.result.questions;
            this.answers = r2.data.result.answers;

            this.setCurrentQuestion();

            this.startDate = new Date();
            // this.startDate = new Date(this.session.startdate);
            this.endDate.setTime(
              this.startDate.getTime() + this.session.duration * 60 * 1000
            );
            this.startDate = DateTimeToString(this.startDate);
            this.endDate = DateTimeToString(this.endDate);
          } else {
            this.notifyVue("danger", r2.data);
          }
        });
      } else {
        this.sessionNotFound = true;
        this.isLoading = false;
        this.notifyVue("danger", r1.data ? r1.data : "Sessiya tapılmadı!");
      }
    });
  },
  beforeRouteLeave(to, from, next) {
    if (
      from.name === "Session" &&
      to.name !== "Result" &&
      !this.sessionNotFound
    ) {
      const answer = window.confirm(
        "Do you really want to leave? Test result will be removed!"
      );
      if (answer) {
        DeleteSession(this.id, this.$store.state.user.id).then();
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
};
</script>

<style lang="scss">
.card label {
  color: black;
  font-size: 14px;
  text-transform: none;
  margin-bottom: 5px;
}
.form-check .form-check-label {
  text-transform: none;
}
.review-quest-div {
  padding-bottom: 10px;
}
.q-report {
  font-size: 12pt;
}
.vertical-scrollable > .row {
  position: absolute;
  top: 120px;
  bottom: 100px;
  left: 180px;
  width: 50%;
  overflow-y: scroll;
}
.unselectable {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.dimScreen {
  position: fixed;
  padding: 0;
  margin: 0;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  background: #999;
}
</style>