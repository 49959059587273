<template>
  <div>
    <loading
      name="lp"
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
    <ValidationObserver v-slot="{ handleSubmit }">
      <card v-if="share.id">
        <div class="row align-items-center">
          <div class="col-12">
            <h3 class="mb-2">
              {{ share.code + ". " + share.title }}
              <small>Dərc tarixi: {{ share.sharedatestr }}</small>
            </h3>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12" style="margin-bottom: 10px">
            <div class="row">
              <div class="col-lg-3">
                <ValidationProvider
                  name="validfrom"
                  rules="required"
                  v-slot="{ passed, failed }"
                >
                  <fg-input
                    label="Date from"
                    rules="required"
                    name="datefrom"
                    :error="failed ? 'The Date From field is required' : null"
                    :hasSuccess="passed"
                  >
                    <el-date-picker
                      :error="failed ? 'The Date From is required' : null"
                      :hasSuccess="passed"
                      @change="dateto = null"
                      v-model="datefrom"
                      type="date"
                      placeholder="Date picker here"
                      :picker-options="optDateFrom"
                    >
                    </el-date-picker>
                  </fg-input>
                </ValidationProvider>
              </div>
              <div class="col-lg-3">
                <ValidationProvider
                  name="dateto"
                  rules="required"
                  v-slot="{ passed, failed }"
                >
                  <fg-input
                    label="Date to"
                    rules="required"
                    name="dateto"
                    :error="failed ? 'The Date To field is required' : null"
                    :hasSuccess="passed"
                  >
                    <el-date-picker
                      v-model="dateto"
                      type="date"
                      placeholder="Date picker here"
                      :picker-options="optDateTo"
                      :error="failed ? 'The Valid To field is required' : null"
                      :hasSuccess="passed"
                    >
                    </el-date-picker>
                  </fg-input>
                </ValidationProvider>
              </div>
              <div class="col-lg-3">
                <button class="btn btn-primary" @click="handleSubmit(onGo)">
                  Go
                </button>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <chart-card
              :chart-data="pieChart.data"
              chart-type="Pie"
              ref="chart"
            >
              <template slot="header">
                <h4 class="card-title">Nəticə diaqramı</h4>
              </template>
            </chart-card>
          </div>
          <div class="col-lg-6">
            <card>
              <h4 class="card-title" style="padding-bottom: 10px">
                Statistics
              </h4>
              <div class="row">
                <div class="col-lg-4"><b>Ümumi sessiya sayı</b></div>
                <div class="col-lg-8">{{ total }}</div>
                <div class="col-lg-4">Uğurlu</div>
                <div class="col-lg-8">{{ pass }}</div>
                <div class="col-lg-4">Uğursuz</div>
                <div class="col-lg-8">{{ fail }}</div>
              </div>
              <h4
                class="card-title"
                style="padding-bottom: 10px; padding-top: 20px"
              >
                Ən yaxşı nəticə
              </h4>
              <div class="row">
                <div class="col-lg-12">
                  <l-table :data="best">
                    <template slot-scope="{ row }">
                      <td>
                        {{ row.testtakername }}
                      </td>
                      <td>
                        {{ row.resemail }}
                      </td>
                      <td>
                        {{ row.score }}
                      </td>
                    </template>
                  </l-table>
                </div>
              </div>
              <h4
                class="card-title"
                style="padding-bottom: 10px; padding-top: 20px"
              >
                Sualların çətinlik dərəcəsi
              </h4>
              <div class="row">
                <div class="col-lg-12">
                  <button class="btn btn-info" @click="onRating()">
                    Reytinq
                  </button>
                </div>
              </div>
            </card>
          </div>
          <div
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <el-input
              type="search"
              class="mb-3"
              style="width: 200px"
              placeholder="Search records"
              v-model="searchQuery"
              aria-controls="datatables"
            />
          </div>

          <div class="col-sm-12">
            <el-table
              stripe
              style="width: 100%"
              :data="
                exams.filter(
                  (c) =>
                    c.resemail
                      .toLowerCase()
                      .indexOf(searchQuery.toLowerCase()) > -1
                )
              "
              border
            >
              <el-table-column prop="startdatestr" label="Date">
              </el-table-column>
              <el-table-column prop="testtakername" label="İmtahan verən">
              </el-table-column>
              <el-table-column prop="resemail" label="Email"> </el-table-column>
              <el-table-column label="Result name">
                <template slot-scope="props">
                  <span :class="statusStyle(props.row.result)"
                    ><b>{{ props.row.resultname }}</b></span
                  >
                </template>
              </el-table-column>

              <el-table-column :width="120" label="Actions">
                <template slot-scope="props">
                  <a
                  @click="onReview(props.row.id)"
                    style="cursor: pointer"
                    v-tooltip.top-center="'View answers'"
                    class="btn-default btn-simple btn-link"
                    ><i class="nc-icon preview"></i>
                  </a>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </card>
    </ValidationObserver>

    <el-dialog
      center
      title="Difficulty rating"
      top="1vh"
      :visible.sync="rateModal"
      width="90%"
      :lock-scroll="false"
    >
      <div>
        <div style="height: 70vh" class="row overflow-auto">
          <div class="col-lg-12">
            <l-table :data="questions">
              <template slot-scope="{ row }">
                <td>
                  <span
                    class="unselectable"
                    :key="row.id"
                    :id="row.id"
                    :name="row.id"
                    >{{ row.question }}</span
                  >
                </td>
                <td style="width: 100px">
                  {{ row.rate + "%" }}
                </td>
              </template>
            </l-table>
          </div>
        </div>
      </div>
    </el-dialog>

    <el-dialog
      center
      title="Review"
      top="1vh"
      :visible.sync="reviewModal"
      width="90%"
      :lock-scroll="false"
    >
      <div>
        <div class="row">
          <div class="col-lg-12 text-center">
            <label class="q-report">Ümumi: {{ questions.length }}</label
            >,
            <label class="q-report"
              >Düzgün:
              {{ questions.filter((c) => c.correct == 1).length }}</label
            >,
            <label class="q-report">Səhv: {{  questions.filter((c) => c.correct == 0).length }}</label>
          </div>
          <div class="col-lg-12">
            <div
              :key="item.id"
              v-for="item in sections"
              style="margin-bottom: 20px"
            >
              <h6>
                {{ item.name }} -
                <span :class="statusStyle(item.result)">{{
                  item.resultname
                }}</span>
              </h6>

              <el-progress
                :text-inside="true"
                :stroke-width="26"
                :percentage="item.resultscore"
                :status="item.result === 1 ? 'success' : 'exception'"
              ></el-progress>
              <small>Keçid balı: {{ item.scoremin + "%" }}</small>
            </div>
          </div>
        </div>
        <div style="height: 70vh" class="row overflow-auto">
          <div class="col-lg-12">
            <l-table :data="questions">
              <template slot-scope="{ row }">
                <td width="50px">
                  <i :class="questionStatus(row.correct)"></i>
                </td>
                <td>
                  <span :key="row.id" :id="row.id" :name="row.id">{{
                    row.orderindex + ". " + row.question
                  }}</span>
                </td>
                <td>
                  {{ row.sectionname }}
                </td>
              </template>
            </l-table>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import {
  Switch,
  DatePicker,
  Table,
  TableColumn,
  Select,
  Option,
  Dialog,
  MessageBox,
  Progress
} from "element-ui";
import { DateToString, AddMonths } from "../../services/helper";
import { Checkbox as LCheckbox, Table as LTable } from "src/components/index";
import { ChartCard } from "src/components/index";
import {
  GetShareSession,
  GetShareSessionStat,
  GetShare,
  GetShareSessionBestScore,
  GetShareSessionQuestionRate,
  GetShareSessionQuestionReview,
  GetSessionSections
} from "../../services/know";
export default {
  components: {
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dialog.name]: Dialog,
    [Switch.name]: Switch,
    [Progress.name]: Progress,
    Loading,
    LCheckbox,
    ChartCard,
    LTable,
  },
  computed: {
    total() {
      return this.pieChart.data.series[0] + this.pieChart.data.series[1];
    },
    pass() {
      return this.pieChart.data.series[0];
    },
    fail() {
      return this.pieChart.data.series[1];
    },
    dtfrom(){
        return DateToString(this.datefrom);
    },
    dtto(){
        return DateToString(this.dateto);
    }
  },

  props: {
    id: String,
    title: String,
  },
  data() {
    return {
      rateModal: false,
      reviewModal: false,
      pieChart: {
        data: {
          labels: ["PASS", "FAIL"],
          series: [0, 0],
        },
      },
      share: { title: "" },
      searchQuery: "",
      isLoading: false,
      datefrom: "",
      dateto: "",
      exams: [],
      best: [],
      questions: [],
      sections: [],
      optDateFrom: {
        disabledDate(date) {
          var dt = new Date();
          dt.setDate(dt.getDate() - 365);
          return date < dt;
        },
      },
      optDateTo: {
        disabledDate: this.disableToDate,
      },
    };
  },
  methods: {
    statusStyle(result) {
      return { "text-success": result === 1, "text-danger": result === 0 };
    },
    questionStatus(status) {
      if (status === 1) return "nc-icon check-single text-success";
      else return "nc-icon e-remove text-danger";
    },
    onRating() {
      this.isLoading = true;
      GetShareSessionQuestionRate(
        this.id,
        this.$store.state.user.id,
        this.dtfrom,
        this.dtto
      ).then((response) => {
        this.isLoading = false;
        if (response.data.result) {
          this.questions = response.data.result;
          this.rateModal = true;
        } else {
          this.notifyVue("danger", response.data);
        }
      });
    },
    onReview(id) {
      this.isLoading = true;
      this.questions = [];
      GetShareSessionQuestionReview(id, this.$store.state.user.id).then(
        (response) => {
          this.isLoading = false;
          if (response.data.result) {
            this.questions = response.data.result;
            GetSessionSections(id, this.$store.state.user.id).then((response2) => {
              this.isLoading = false;
              if (response2.data.result) {
                this.sections = response2.data.result;
              } else {
              }
            });
            this.reviewModal = true;
          } else {
              
            this.notifyVue("danger", response.data);
          }
        }
      );
    },
    onGo() {
      this.isLoading = true;
      GetShareSession(
        this.id,
        this.$store.state.user.id,
        this.dtfrom,
        this.dtto
      ).then((response) => {
        this.isLoading = false;
        if (response.data.result) {
          this.exams = response.data.result;

          GetShareSessionStat(
            this.id,
            this.$store.state.user.id,
            this.dtfrom,
            this.dtto
          ).then((response) => {
            this.pieChart.data.labels = ["PASS", "FAIL"];
            this.pieChart.data.series = [0, 0];
            if (response.data.result) {
              let total = 0;
              if (response.data.result.length > 0) {
                for (let i = 0; i < response.data.result.length; i++) {
                  this.pieChart.data.series[i] = response.data.result[i].count;
                  total += response.data.result[i].count;
                }

                for (let i = 0; i < response.data.result.length; i++) {
                  this.pieChart.data.labels[i] =
                    response.data.result[i].resultname +
                    " - " +
                    Math.round((100 * response.data.result[i].count) / total) +
                    "%";
                }
              }
            }
            this.$refs.chart.initChart();
          });
        } else {
          this.exams = [];
        }
      });
    },
  },
  mounted() {
    this.dateto = DateToString(new Date());
    this.datefrom = DateToString(AddMonths(new Date(), -1));
    GetShare(this.id, this.$store.state.user.id).then((response) => {
      if (response.data.result) {
        this.share = response.data.result;
        GetShareSessionBestScore(
          this.id,
          this.$store.state.user.id,
          this.dtfrom,
          this.dtto
        ).then((response) => {
          if (response.data.result) {
            this.best = response.data.result;
          } else this.best = [];
        });
      } else {
      }
    });
    this.onGo();
  },
};
</script>

<style lang="scss" scoped>
</style>